@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@font-face {
    font-family: 'Open Sans';
    src: url('/public/fonts/OpenSans-Regular.ttf') format('truetype');
}

.home-container{
    background-color: #121826;
    font-family: 'Open Sans';
    overflow-x: hidden;
}

.header-links-ul > li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}
.react-link{
    text-decoration: none;
    color: inherit;
}
ul {
    list-style-type: none;
}
.logo-text {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
}
.column{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.sign-in-link {
    color: #2E6CFF;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.space-around {
    justify-content: space-around;
}
.align-center {
    align-items: center;
}
.space-between {
    justify-content: space-between;
}
nav {
    width: 90vw;
    padding: 45px;
}
.visible {
    visibility: visible;
}
.nav-div {
    width: inherit;
    align-items: center;
}
.header-links-container{
    width: 279px;
}
.main-header-text{
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    line-height: 71px;
    text-align: center;
    color: #FFFFFF;
}
.sub-header-text{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}
.first-page {
    padding: 5px 10px 5px 10px;
}
.button-set-1 {
    width: 361px;
    height: 56px;
    margin-top: 24px;
    margin-bottom: 118px;
}
.filled-button{
    color: #FFFFFF;
    background: #177AE5;
    border: 1px solid rgba(33, 41, 52, 0.07);
    box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.05);
    border-radius: 6px;
    width: 170px;

}
.outlined-button {
    color: #177AE5;
    border: 1px solid #177AE5;
    background: transparent;
    border-radius: 6px;
    width: 170px;

}
.platforms-title-header{
    
    height: 22px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #727272;
}
.second-page{
    margin-top: 150px;
}
.second-page > div {
    gap: 10vw;
}
.second-page > div div {
    align-items: center;
}
.feature-growth-container {
    width: 70vw;
}
.growth-text {
    width: 325px;
    color: #FFFFFF;
}
.feature-analytics-container{
    width: 80vw;
    color: #FFFFFF;
}
.analytics-text {
    width: 424px;
}
.main-header-text-2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
    margin-top: 38px;
    margin-bottom: 50px;
}
.sub-header-text-2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}
.platforms-logo-image{
    width: 552px;
    height: 76px;
}
.blue-in-text{
    color: #177AE5;
}
.third-page {
    background: #121826;
    margin-top: 200px;
    gap: 50px;
    padding-bottom: 150px;
   
}
.userpic {
    width: 32px;
    height: 32px;
}
.user-details{
    gap: 10px;
}
.no-margin{
    margin-bottom: 0;
    margin-top: 5px;
}
.grey {
    color: #485D8B;
}
.customer-reviews {
    color: #FFFFFF;
    margin-top: 70px;
    background: #222838;
    border-radius: 8px;
    padding-bottom: 55px;
    width: 80vw;
    height: 750px;
}
.signup-block{
    margin-top: 87px;
    width: 75vw;
    background: #222838;
    border-radius: 8px;
    padding-top: 71px;
    margin-bottom: 30px;
}
.links-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}
.card-grid-container {
    width: 797px;
    height: 520px;
    gap: 7px;
}
.card-grid-container > div {
    gap: 16px;
}
.card {
    background: #121826;
    border-radius: 8px;
    padding: 27px 23px;
    gap: 10px;
    width: 210px;
    font-weight: 400;
    font-size: 12px;
}
.card-regular{ 
    height: 239px;
}

.card-large{
    height: 289px;
}
.card-small{
    height: 195px;
}
.card-square{
    height: 252px;
}
.main-header-text-3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #ADBBDB;

}
.fourth-page {
    background: #222838;
    padding-bottom: 30px;
}
.footer {
    padding-left: 92px;
    padding-top: 106px;
    padding-right: 113px;
}
.footer-links-socials-subscribe-container{
    width: 80vw;
    margin-top:66px;
}
.footer-links {
    gap:16px;
    padding: 0;
}
.footer-links > li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}
.socials-link-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    margin-bottom: 24px;
}
.subscribe-column {
    width: 322px;
    justify-content: left;
    align-items: flex-start;
}
.subscribe-text-header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}
.subscribe-text-sub-header {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 5px;
}
.subscribe-textfield {
    width: 322px;
    height: 43px;
    border: 1px solid #ADBBDB;
    border-radius: 6px;
    margin-bottom: 18px;
    background-color: transparent;
}
.filled-button-long {
    width: 323px;
    height: 43px;
    background: #177AE5;
    border: 1px solid rgba(33, 41, 52, 0.07);
    box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.05);
    border-radius: 6px;
    color: #FFFFFF;
}
.hr {
    width: 85vw;
    margin-bottom: 34px;
    margin-top: 66px;
}
.copyright {
    margin-bottom: 34px;
    color: #FFFFFF;
}
.carousel-component {
    display: none;
}
.platforms-logo-image-small {
     display: none;
}
.toggle-button {
    display: none;
}
.second-page-small {
    display: none;
}
.subscribe-column-small {
    display: none;
}
.ellipse1 {
    position: absolute;
    width: 745px;
    height: 784px;
    left: 20%;
}
.joined-ellipse1 {

    position: absolute;
    width: 432px;
    height: 438px;
    top: 770px;
    left: 70px;
}
.joined-ellipse2 {
    position: absolute;
    width: 432px;
    height: 438px;
    top: 1250px;
    right: 90px;
    
}

@media only screen and (max-width: 600px) {
    .card-grid-container{
        display: none;
    }
    .platforms-logo-image-small {
        display: block;
        width: 269px;
        margin-top: 24px;
    }
    .platforms-logo-image {
        display: none;
    }
    .toggle-button {
        display: flex;
    }
    .header-links-container, .sign-in-link{
        display: none;
    }
    .main-header-text {
        font-size: 32px;
        line-height: 44px;
    }
    .platforms-title-header {
        width: 277px;
        text-align: center;
    }
    .second-page-small {
        display: flex;
        margin-top: 80px;
        padding-left: 15px;
    }
    .second-page {
        display: none;
    }
    .analytics-text {
        width: 336px;
    }
    .feature-analytics-container {
        width: inherit;
    }
    .feature-growth-container {
        width: inherit;
    }
    .carousel-component {
        display: block;
        height: 400px;
    }
    .signup-block {
        width: 100vw;
    }
    .subscribe-column-small {
        display: flex;
    }
    .subscribe-column-small > input {
       width: 80vw;
    }
    .subscribe-column-small > button {
        width: 80vw;
    }
    .subscribe-column {
        display: none;
    }
    .footer {
        padding: 10px 0px 0px 40px;
    }
    .customer-reviews {
        background: none;
    }
    #home-container {
        overflow: hidden;
    }
    .signup-block {
        margin-top: 0;
    }
    .body{
        padding-bottom: 0;
    }
    .third-page {
        margin-top: 0;
    }
    .footer-links-socials-subscribe-container{
        margin-top: 0;
    }
    .footer .logo-text {
        text-align: left;
    }
    hr {
        width: inherit;
    }
    .fourth-page {
        width: 100vw;
    }
    .hr-div {
        padding-right: 20px;
        width: 80vw;
        margin-right: 20px;
    }
    .customer-reviews {
        padding-bottom: 0;
    }
    .ellipse1 {
        position: absolute;
        width: 50%;
        height: 284px;
      
        overflow: hidden;

    }
    .joined-ellipse1 {
        position: absolute;
        width: 80%;
        height: 438px;
        top: 820px;
        right: 70px;
        left: 0;
    }
    .joined-ellipse2 {
        position: absolute;
        width: 80%;
        height: 438px;
        top: 1310px;
        right: 70px;
        
    }
}
