@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,600");

body {
  margin: 0;
  font-family: "Open Sans";
}
.dashboard-discovery-flex-row {
    display: flex;
    flex-direction: row;
}
.dashboard-discovery-active-link {
  background: #4323FF;
  
}
.dashboard-discovery-align-center {
  align-items: center;
}
.dashboard-discovery-logout {
  margin-left: 25px;
}
.dashboard-discovery-nav-header {
  margin-left: 15px;
}
.dashboard-discovery-flex-column {
    display: flex;
    flex-direction: column;
}
.dashboard-discovery-carousel {
    background: transparent;
  }

 .dashboard-discovery-carousel-cell {
    width: 66%;
    height: 200px;
    margin-right: 10px;
    counter-increment: gallery-cell;
  }
 .dashboard-discovery-carousel-cell-tab {
    width: 40%;
    margin-right: 10px;
    border-radius: 5px;
    counter-increment: carousel-cell;
  }

  /* cell number */
 .dashboard-discovery-carousel-cell:before {
    display: block;
    text-align: center;
    line-height: 200px;
    font-size: 80px;
    color: white;
  }

/**/

.dashboard-discovery-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  /*! align-items: center; */
}
.dashboard-discovery-side-nav {
    background-color: rgba(20, 0, 135, 1);
    display: flex;
    flex-direction: column;
    width: 15vw;
    color: white;
  gap: 10vh;
  position: fixed;

}
.dashboard-discovery-overview-links,.dashboard-discovery-tool-links {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.dashboard-discovery-overview-links div,.dashboard-discovery-tool-links div {
    display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.dashboard-discovery-overview-hover {
  cursor: pointer;
  
}
.dashboard-discovery-overview-hover:hover {
  background: #4323FF;
}


.dashboard-discovery-upgrade-plan-popup {
  align-items: center;
  background-color:
#f7f6ff;
  border: 1px none;
  border-radius: 8px;
  display: flex;
  gap: 89px;
  height: 177px;
  padding: 0 24px;
  width: 600px;
}

.dashboard-discovery-flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
  min-height: 122px;
  width: 273px;
}

.dashboard-discovery-upgrade-your-plan {
  color: var(--tuna);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  height: 22px;
  letter-spacing: 0;
  line-height: normal;
}

.dashboard-discovery-flex-align-text-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-discovery-x70-discounts-for-1-year-subscription {
  color:
transparent;
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  height: 19px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 8px;
}

.dashboard-discovery-button-container {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  height: 40px;
  margin-top: 33px;
  min-width: 283px;
}

.dashboard-discovery-upgrade-button:hover {
  transform: scale(1.1);
}

.dashboard-discovery-upgrade-button {
  align-items: center;
  background-color: var(--ultramarine);
  border: 1px solid;
  border-color:
#21293412;
  border-radius: 6px;
  box-shadow: 0px 1px 0px
#1b1f230d;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  transition: all 0.2s ease;
  width: -moz-fit-content;
  width: fit-content;
  width: 161px;
height: 40px;
}

.dashboard-discovery-button-text {
  align-items: center;
  border: 1px none;
  border-radius: 6px;
  display: flex;
  gap: 8px;

  justify-content: center;


}

.dashboard-discovery-upgrade-button-text {
  color:#f5f8fa;
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;

  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.dashboard-discovery-remind-me-later-button:hover {
  transform: scale(1.1);
}

.dashboard-discovery-remind-me-later-button {
  align-items: center;
  border: 1px solid;
  border-color: var(--ultramarine);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  width: 161px;
height: 40px;
  justify-content: center;
  transition: all 0.2s ease;

}

.dashboard-discovery-remind-me-later-button-text {
  color: var(--ultramarine);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
 width: 161px;
height: 0px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.dashboard-discovery-upgrade-plan-img {
  height: 159px;
  width: 213px;
}

.dashboard-discovery-explore-header-text {
    left: 0;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 322px;
    white-space: nowrap;
    width: 92px;
}

.dashboard-discovery-podcast-genre {
    align-items: center;
    border: 1px none;
    display: flex;
    gap: 32px;
    width: -moz-fit-content;
    width: fit-content;
}
.dashboard-discovery-top-podcasts:hover {
    color:
  #d72727;
  }

 .dashboard-discovery-top-podcasts {
    cursor: pointer;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    transition: all 0.2s ease;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-active {
    align-items: flex-start;
    background-color:
  #2100e338;
    border: 1px none;
    border-radius: 16px;
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-recently-played {
    color:
  #6200ee;
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    text-align: center;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-bestsellers {
    cursor: pointer;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    transition: all 0.2s ease;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-bestsellers:hover {
    color:
  #f60e0e;
  }

 .dashboard-discovery-new-podcasts:hover {
    color:
  #ee0a0a;
  }

 .dashboard-discovery-new-podcasts {
    cursor: pointer;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    transition: all 0.2s ease;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-card {
    background-color: var(--white-lilac);
    border: 1px none;
    border-radius: 8px;
    cursor: pointer;
    height: 156px;
    overflow: hidden;
    transition: all 0.2s ease;
    width: 434px;
    padding-left: 10px;
    padding-top: 10px
  }
 .dashboard-discovery-card-img {
    height: 140px;
    object-fit: cover;
    width: 151px;

  }
 .dashboard-discovery-card-header {
        left: 197px;
        letter-spacing: 0;
        line-height: 28px;
        white-space: nowrap;
        width: 165px;
  }

  @font-face {
    font-family: "Circular Std-Medium";
    font-style: normal;
    font-weight: 500;
    src: url("https://fonts.animaapp.com/CircularStd-Medium") format("truetype");
  }

 .dashboard-discovery-card:hover {
    transform: scale(1.1);
  }

 .dashboard-discovery-card {
    background-color: var(--white-lilac);
    border: 1px none;
    border-radius: 8px;
    cursor: pointer;
    height: 176px;
    transition: all 0.2s ease;
    width: 400px;
    display: flex;
    gap: 35px;
  }
.dashboard-discovery-card-header {
    justify-content: space-between;
    width: 200px;
  }
 .dashboard-discovery-time-play {
    justify-content: space-between;
    width: 200px;
  }
.dashboard-discovery-badges {
  display: flex;
  justify-content: space-between;
  width: 90px;
}



.dashboard-discovery-card-img {
    height: 140px;
    left: 12px;
    object-fit: cover;
    width: 151px;
  }

 .dashboard-discovery-line-808 {
    height: 1px;
    left: 197px;
    object-fit: cover;
    position: absolute;
    top: 82px;
    width: 215px;
  }

 .dashboard-discovery-by-nse-abasi-etim {
    left: 197px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 38px;
    white-space: nowrap;
    width: 122px;
  }

 .dashboard-discovery-group-43700 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    left: 197px;
    min-height: 52px;
    position: absolute;
    top: 99px;
    width: 126px;
  }

 .dashboard-discovery-x1h-34m-1 {
    letter-spacing: 0;
    line-height: 28px;
    white-space: nowrap;
  }

 .dashboard-discovery-badge-container {
    align-items: flex-start;
    display: flex;
    gap: 8px;
    height: 16px;
    min-width: 124px;
  }

 .dashboard-discovery-badge-1 {
    align-items: flex-start;
    background-color: var(--pattens-blue);
    border: 1px none;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    padding: 2px 8px;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-blue-1 {
    letter-spacing: 0;
    line-height: 12px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-badge {
    align-items: flex-start;
    background-color: var(--jagged-ice);
    border: 1px none;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    padding: 2px 8px;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-green-1 {
    letter-spacing: 0;
    line-height: 12px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-discovery-icon-outline-menu-v-3:hover {
    transform: scale(1.1);
  }

 .dashboard-discovery-icon-outline-menu-v-3 {
    cursor: pointer;
    height: 24px;
    left: 392px;
    position: absolute;
    top: 15px;
    transition: all 0.2s ease;
    width: 24px;
  }

 .dashboard-discovery-utility-play-large {
    align-items: flex-end;
    border: 1px none;
    display: flex;
    height: 32px;
    justify-content: flex-end;
    left: 381px;
    min-width: 31px;
    padding: 4px 3px;
    position: absolute;
    top: 109px;
  }

 .dashboard-discovery-overlap-group {
    align-items: flex-start;
    background-color: var(--ultramarine);
    border-radius: 12px;
    display: flex;
    height: 24px;
    justify-content: flex-end;
    min-width: 24px;
    padding: 7px 7px;
  }

 .dashboard-discovery-rectangle-359 {
    height: 10px;
    width: 8px;
  }

 .dashboard-discovery-surname {
    left: 197px;
    letter-spacing: 0;
    line-height: 28px;
    white-space: nowrap;
    width: 165px;
  }

 .dashboard-discovery-tab {
    align-items: flex-start;
    background-color: var(--zircon);
    border-radius: 8px;
    border-style: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px 16px 0px;
    transition: all 0.2s ease;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-discovery-tab:hover {
    transform: scale(1.1);
  }
 .dashboard-discovery-tab-div-1 {
    align-items: center;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-discovery-tab-info {
    align-items: center;
    border: 1px none;
    display: flex;
    gap: 17px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-discovery-tab-highlight {
    background-color: var(--blue-marguerite);
    border: 1px none;
    border-radius: 8px 8px 0px 0px;
    height: 4px;
    min-width: 111px;
  }
 .dashboard-discovery-tab-img-div {
    background-color: var(--gallery);
    border-radius: 29px;
    height: 58px;
    min-width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.dashboard-discovery-friends-activity-header-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 120px;
}

.dashboard-discovery-friends-activity {
  margin-top: 20px;
}
.dashboard-discovery-friends-activity img {
  margin-bottom: 0
}
.dashboard-discovery-friend-podcast-audio {
   align-items: center;
   border: 1px none;
   display: flex;
   gap: 16px;
   transition: all 0.2s ease;
   width: -moz-fit-content;
   width: fit-content;
  cursor: pointer;
}
.dashboard-discovery-friend-podcast-audio:hover {
    transform: scale(1.1)
}
.dashboard-discovery-friend-podcast-img-div {
    border: 1px none;
    height: 40px;
    min-width: 40px;
}
.dashboard-discovery-activity-statistics {
    display: flex;
    flex-direction: column;
  gap: 30px;
}
.dashboard-discovery-stat {
    align-items: center;
    border: 1px none;
    display: flex;
    gap: 20px;
    width: -moz-fit-content;
    width: fit-content;
}
.dashboard-discovery-day-duration-div {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: -moz-fit-content;
    width: fit-content;
}
.dashboard-discovery-stats-percentage-green {
  color: green;
}
.dashboard-discovery-stats-percentage-blue {
  color: blue;
}
.dashboard-discovery-stats-percentage-yellow {
  color: yellow;
}
.dashboard-discovery-content {
  display: flex;
  gap: 10vw;


}
.dashboard-discovery-main-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 600px;
  margin-left: 220px;
}
.dashboard-discovery-explore-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px
}
/*Fonts*/
:root {
    --black:
  rgba(0, 0, 0, 1);
    --endeavour:
  rgba(0, 89, 172, 1);
    --ultramarine:
  rgba(19, 0, 134, 1);
    --eucalyptus:
  rgba(21, 137, 87, 1);
    --eerie-black:
  rgba(27, 30, 33, 1);
    --dark-blue:
  rgba(33, 0, 227, 0.22);
    --charade:
  rgba(33, 41, 52, 0.071);
    --blue:
  rgba(37, 1, 249, 1);
    --tuna:
  rgba(54, 60, 67, 1);
    --chateau-green:
  rgba(54, 179, 104, 1);
    --canary:
  rgba(67, 35, 255, 1);
    --ocean-blue-pearl:
  rgba(81, 60, 204, 1);
    --scarpa-flow:
  rgba(82, 89, 100, 1);
    --electric-violet:
  rgba(98, 0, 238, 1);
    --purple-heart:
  rgba(101, 75, 255, 1);
    --pale-sky:
  rgba(109, 119, 134, 1);
    --blue-marguerite:
  rgba(121, 98, 255, 1);
    --regent-gray:
  rgba(136, 149, 167, 1);
    --gray:
  rgba(145, 145, 145, 1);
    --cornflower:
  rgba(151, 209, 242, 1);
    --portage:
  rgba(152, 134, 255, 1);
    --delta:
  rgba(162, 162, 162, 1);
    --heather:
  rgba(184, 196, 206, 1);
    --jagged-ice:
  rgba(196, 239, 223, 1);
    --mischka:
  rgba(206, 214, 222, 1);
    --alto:
  rgba(217, 217, 217, 1);
    --pattens-blue:
  rgba(221, 238, 255, 1);
    --tahiti-gold:
  rgba(224, 131, 4, 1);
    --blue-chalk:
  rgba(233, 228, 255, 1);
    --solitude:
  rgba(234, 246, 252, 1);
    --gallery:
  rgba(237, 237, 237, 1);
    --cultured-pearl:
  rgba(245, 245, 245, 1);
    --black-squeeze:
  rgba(245, 248, 250, 1);
    --white-lilac:
  rgba(249, 247, 255, 1);
    --zircon:
  rgba(249, 248, 255, 1);
    --alabaster:
  rgba(250, 250, 250, 1);
    --amber:
  rgba(255, 196, 0, 1);
    --peach-cream:
  rgba(255, 239, 219, 1);
    --white:
  rgba(255, 255, 255, 1);

    --font-size-xs: 10px;
    --font-size-s: 12px;
    --font-size-m: 14px;
    --font-size-l: 16px;
    --font-size-xl: 18px;
    --font-size-xl2: 20px;
    --font-size-xxl2: 24px;
    --font-size-xxl22: 22px;

    --font-family-circular_std-book: "Circular Std-Book";
    --font-family-circular_std-medium: "Circular Std-Medium";
    --font-family-hero_new-medium: "Hero New-Medium";
    --font-family-hero_new-semibold: "Hero New-SemiBold";
    --font-family-montserrat: "Montserrat";
    --font-family-montserrat-black: "Montserrat-Black";
    --font-family-open_sans: "Open Sans";
    --font-family-open_sans-bold: "Open Sans-Bold";
  }



 .dashboard-discovery-opensans-semi-bold-eerie-black-14px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-regent-gray-12px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-discovery-opensans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-gray-14px {
    color: var(--gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-scarpa-flow-14px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-electric-violet-16px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-pale-sky-12px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-eerie-black-12px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-chateau-green-12px {
    color: var(--chateau-green);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-blue-16px {
    color: var(--blue);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-eucalyptus-10px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-electric-violet-18px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-electric-violet-20px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-heather-14px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-electric-violet-14px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-purple-heart-16px {
    color: var(--purple-heart);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-tahiti-gold-12px {
    color: var(--tahiti-gold);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-regent-gray-16px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-solitude-14px {
    color: var(--solitude);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-ocean-blue-pearl-14px {
    color: var(--ocean-blue-pearl);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-tuna-14px {
    color: var(--tuna);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-scarpa-flow-22px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl22);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-scarpa-flow-20px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-white-10px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-circularstd-medium-endeavour-10px {
    color: var(--endeavour);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-eucalyptus-12px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-cornflower-14px {
    color: var(--cornflower);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-regent-gray-14px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-circularstd-book-normal-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-circular_std-book);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-bold-scarpa-flow-24px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl2);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-regent-gray-10px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-black-squeeze-14px {
    color: var(--black-squeeze);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-bold-white-16px {
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-scarpa-flow-10px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-discovery-opensans-semi-bold-white-20px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-discovery-opensans-normal-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }