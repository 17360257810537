body {
    margin: 0;
    padding: 0;
}
nav {
   /* visibility: hidden; */
}
.text-box {
    color: #2653C9;
    ;
}
.margin-bottom-48px {
    margin-bottom: 48px;
}

.heading {
    font-family: 'Poppins';
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
}
.sub-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}
.button-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 79px;
    margin-left: 200px;
    margin-right: 200px;
    
}
.blue-button {  
    font-family: 'Poppins';
    background: #1F3E8B;
    width: 227px;
    height: 56px;       
    border-radius: 5px;
    color: white;
}
.white-button {
    font-family: 'Poppins';
    border: 2px solid #1F3E8B;
    width: 227px;
    height: 56px;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.07));
    border-radius: 10px;
}
.sad-pic {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 200px) and (max-width: 900px) {
    .button-span {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        justify-content: space-around;
        margin-top: 79px;
        margin-left: 200px;
        margin-right: 200px;
        
    }
}