@media only screen and (max-width: 600px) {
    .body {
        height: 600px;
        margin: 0;
        display: grid;
        grid-template-rows: 500px 100px;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center; 
      }
      
     main#carousel {
        grid-row: 1 / 2;
        grid-column: 1 / 8;
        width: 342.85px;
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transform-style: preserve-3d;
        perspective: 70px;
        --items: 6;
        --middle: 3;
        --position: 1;
        pointer-events: none;
      }
      
      div.item {
        position: absolute;
        width: 250px;
        height: 240px;
         border-radius: 12px;
        background-color: coral;
        --r: calc(var(--position) - var(--offset));
        --abs: max(calc(var(--r) * -1), var(--r));
        transition: all 0.25s linear;
        transform: rotateY(calc(-10deg * var(--r)))
          translateX(calc(-300px * var(--r)));
        z-index: calc((var(--position) - var(--abs)));
      }
      
      div.item:nth-of-type(1) {
        --offset: 1;
        background-color: #121826;
        color: white;
         border-radius: 12px;
      }
      div.item:nth-of-type(2) {
        --offset: 2;
        background-color: #121826;
        color: white;
         border-radius: 12px;

      }
      div.item:nth-of-type(3) {
        --offset: 3;
        background-color: #121826;
        color: white;
         border-radius: 12px;
  
      }
      div.item:nth-of-type(4) {
        --offset: 4;
        background-color: #121826;
        color: white;
         border-radius: 12px;
      }
      div.item:nth-of-type(5) {
        --offset: 5;
        background-color: #121826;
        color: white;
         border-radius: 12px;
      }
      div.item:nth-of-type(6) {
        --offset: 6;
        background-color: #121826;
        color: white;
         border-radius: 12px;
      }
      
      input:nth-of-type(1) {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }
      input:nth-of-type(1):checked ~ main#carousel {
        --position: 1;
      }
      
      input:nth-of-type(2) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
      }
      input:nth-of-type(2):checked ~ main#carousel {
        --position: 2;
      }
      
      input:nth-of-type(3) {
        grid-column: 4 /5;
        grid-row: 2 / 3;
      }
      input:nth-of-type(3):checked ~ main#carousel {
        --position: 3;
      }
      
      input:nth-of-type(4) {
        grid-column: 5 / 6;
        grid-row: 2 / 3;
      }
      input:nth-of-type(4):checked ~ main#carousel {
        --position: 4;
      }
      
      input:nth-of-type(5) {
        grid-column: 6 / 7;
        grid-row: 2 / 3;
      }
      input:nth-of-type(5):checked ~ main#carousel {
        --position: 5;
      }

      input:nth-of-type(6) {
        grid-column: 7 / 8;
        grid-row: 2 / 3;
      }
      input:nth-of-type(6):checked ~ main#carousel {
        --position: 6;
      }
      .column{
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
      }
      .card_medium{
          height: 259px;
      }
      .card-carousel {
          padding: 27px 23px;
          gap: 10px;
          font-weight: 400;
          font-size: 12px;
      }
      .row{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
      }
      .align-center {
          align-items: center;
      }
      .user_details{
          gap: 10px;
      }
      .userpic {
          width: 32px;
          height: 32px;
      }
      .no_margin{
          margin-bottom: 0;
          margin-top: 5px;
      }
      .grey {
          color: #485D8B;
      }
}
