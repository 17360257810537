@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,600");

body {
    margin: 0;
    font-family: "Open Sans";
  }
 .dashboard-main-flex-row {
      display: flex;
      flex-direction: row;
  }
 .dashboard-main-align-center {
    align-items: center;
  }
 .dashboard-main-align-text-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
 .dashboard-main-logout {
    margin-left: 25px;
  }
.dashboard-main-nav-header {
    margin-left: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
 .dashboard-main-flex-column {
      display: flex;
      flex-direction: column;
  }
 .dashboard-main-container {
    display: flex;
    flex-direction: row;
     align-items: center;
  }
 .dashboard-main-overview-links,.dashboard-main-tool-links {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    color: white;
  }
 .dashboard-main-overview-links div,.dashboard-main-tool-links div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
.dashboard-main-overview-hover {
  cursor: pointer;
  width: 100%;
}
.dashboard-main-overview-hover:hover{
   background-color: #4323ff;
}
 .dashboard-main-logout {
    font-family: "Open Sans";
    gap: 5px;
  }
 .dashboard-main-logout.dashboard-main-align-center {
    display: flex;
    flex-direction: row;
  }
 .dashboard-main-side-nav {
    background-color: rgba(20, 0, 135, 1);
    display: flex;
    flex-direction: column;
    width: 15vw;
    color: white;
    gap: 10vh;
    margin: 0;
    position: fixed;
    top: 0;
    overflow: hidden;
  }
.dashboard-main-main-content {
  width: 48vw;
  margin-left: 270px;
  overflow-x: clip;
}
.dashboard-main-sub-content{
  /*! width: 30vw; */
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
 .dashboard-main-dashboard-div-container {
    align-items: flex-end;
    border: 1px none;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 86px;
    padding: 9px 0;
  }
 .dashboard-main-dashboard-div {
    align-items: flex-start;
    background-color: var(--alabaster);
    display: flex;
    height: 67px;
    padding: 11px 31px;
    width: 100%;
  }
 .dashboard-main-dashboard-text {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
    white-space: nowrap;
  }

 .dashboard-main-period-stats-div {
  /*! margin-left: 210px; */
    display: flex;
}
 .dashboard-main-gen-stats-frame {
    align-items: flex-start;
    background-color: var(--white);
    border: 1px none;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    padding: 19px 18px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-main-gen-stats-content-frame {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-main-stats-img-top {
    height: 24px;
    min-width: 24px;
  }
 .dashboard-main-stats-info-div {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-main-stats-header {
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-main-stats-figure-and-duration-div {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-main-stats-figure {
    letter-spacing: 0;
    line-height: 26px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-main-stats-duration-img-div {
    height: 24px;
    margin-right: -2px;
    min-width: 123px;
    position: relative;
  }
 .dashboard-main-stats-duration {
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
 .dashboard-main-stats-duration-img {
    height: 16px;
    left: 105px;
    position: absolute;
    top: 4px;
    width: 16px;
  }

.dashboard-main-activity-index-analytics-header {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl);
    font-weight: 600;
    height: 21px;
    letter-spacing: 0;
    line-height: 21px;
    min-width: 201px;
    white-space: nowrap;
  }
 .dashboard-main-chart-container{
    position: relative;
    height: 50vh;
    width: 90vw;
 }
table{
  width: 48vw;
  text-align: center;
  border-spacing: 15px;
}
  tr.table-header th {
    background: #F5F5F5;
  }
.dashboard-main-table-header{
    background: #F5F5F5;
    height: 40px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #8895A7;
  position: -webkit-sticky;
  position: sticky;
  top: 95px;
  }
.dashboard-main-podcast-title-td {
  align-items: center;
  display: flex;
  gap: 16px;
  height: 40px;
  min-width: 50px;
}

.dashboard-main-podcast-info-div {
    align-items: center;
    display: flex;
    gap: 16px;
    height: 40px;
    justify-content: flex-end;
    margin-top: 28px;
    min-width: 168px;
  }
 .dashboard-main-podcast-title-and-name-div {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 0;
    min-height: 40px;
    width: 124px;
  }
 .dashboard-main-podcast-title {
    height: 20px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    width: 120px;
  }
 .dashboard-main-podcaster-name {
    height: 16px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
    white-space: nowrap;
    width: 65px;
  }
.dashboard-main-download-text {
    height: 20px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
  }
 .dashboard-main-release-date-text {
    height: 20px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 40px;
    text-align: center;
    white-space: nowrap;
    width: 56px;
  }
 .dashboard-main-badge-pill-yellow {
    background-color: #ffefdb;
    border: 1px none;
    border-radius: 18px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center
  }
 .dashboard-main-badge-pill-green {
    align-items: center;
    background-color: var(--jagged-ice);
    border: 1px none;
    border-radius: 18px;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 4px 8px;
    width: -moz-fit-content;
    width: fit-content;
  }
.dashboard-main-status-td{
  padding-left:5px;
  text-align: center;
  align-items: center;
  justify-content: center
}
.dashboard-main-table-row {
  display: table-row;
  
}

 .dashboard-main-yellow {
    color:
  #e08304;
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-main-green {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-main-podcaster-of-the-month-frame {
    align-items: flex-start;
    background-color: var(--ultramarine);
    border: 1px none;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-top: 12px;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-main-podcaster-of-the-month-content-div {
    border: 1px none;
    height: 135px;
  }

 .dashboard-main-podcaster-of-the-month-group-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    min-height: 135px;
  }

 .dashboard-main-podcaster-of-the-month-text {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    height: 17px;
    letter-spacing: 0;
    line-height: 17px;
    white-space: nowrap;
  }

 .dashboard-main-podcaster-of-the-month-name {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    height: 19px;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 5px;
    white-space: nowrap;
  }

 .dashboard-main-button-view-profile {
    align-items: center;
    background-color: var(--solitude);
    border: 1px solid;
    border-color:
  #21293412;
    border-radius: 6px;
    box-shadow: 0px 1px 0px
  #1b1f230d;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-left: 4px;
     margin-top: 22px; 
    width: 113px;
    height: 32px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
.dashboard-main-button-view-profile:hover{
  transform: scale(1.1)
}

 .dashboard-main-button-curve {
    align-items: center;
    border: 1px none;
    border-radius: 6px;
    display: flex;
    gap: 8px;
    height: 32px;
    justify-content: center;
    padding: 8px 16px;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-main-button-text {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    text-align: center;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-main-rectangle-3136 {
    height: 167px;
    left: 223px;
    /*! position: absolute; */
    top: 0;
    /*! width: 123px; */
  }

 .dashboard-main-rectangle-3137 {
    background: linear-gradient(180deg, rgb(19.68, 0, 134.94) 0%, rgba(19.68, 0, 134.94, 0) 100%);
    border: 1px none;
    height: 168px;
    left: 187px;
    /*! position: absolute; */
    top: 0;
    width: 189px;
  }

 .dashboard-main-avatars {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    height: 24px;
    min-width: 88px;
    margin-top: 20px;
  }

 .dashboard-main-avatars-container {
    height: 24px;
    width: 88px;
    display: flex;

  }

 .dashboard-main-avatars-web-5 {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 32px;
    min-width: 24px;
    /*! position: absolute; */
    top: 0;
  }

 .dashboard-main-background {
    height: 28px;
    margin-top: -2px;
    object-fit: cover;
    width: 28px;
  }

 .dashboard-main-avatars-image24-default {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 64px;
    min-width: 24px;
    /*! position: absolute; */
    top: 0;
  }

 .dashboard-main-overlap-group-2 {
    align-items: flex-start;
    background-color: var(--black-squeeze);
    border: 2px solid;
    border-color: var(--white);
    border-radius: 24px;
    display: flex;
    height: 23px;
    margin-top: -5px;
    min-width: 28px;
    padding: 3px 0;
  }

 .dashboard-main-cu {
    color: var(--scarpa-flow);
    font-family: var(--font-family-circular_std-book);
    font-size: var(--font-size-s);
    font-weight: 400;
    height: 16px;
    letter-spacing: 0;
    line-height: 16px;
    min-width: 25px;
    text-align: center;
    white-space: nowrap;
  }

 .dashboard-main-avatars-web-6 {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 48px;
    min-width: 24px;
    /*! position: absolute; */
    top: 0;
  }

 .dashboard-main-avatars-web-3 {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 0;
    min-width: 24px;
    /*! position: absolute; */
    top: 0;
  }

 .dashboard-main-avatars-web-4 {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 16px;
    min-width: 24px;
    /*! position: absolute; */
    top: 0;
  }
 .dashboard-main-section-header {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl);
    font-weight: 600;
    height: 21px;
    letter-spacing: 0;
    line-height: 21px;
    min-width: 201px;
    white-space: nowrap;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
.dashboard-main-card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
 .dashboard-main-card-frame {
    align-items: center;
    border: 1px none;
    display: flex;
    gap: 118px;
    left: 20px;
    /*! position: absolute; */
    top: 270px;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-main-card-podcast-info {
    align-items: center;
    border: 1px none;
    display: flex;
    gap: 16px;
    width: -moz-fit-content;
    width: fit-content;
  }

 .dashboard-main-podcast-creator-and-podcast-name {
    height: 40.5px;
    margin-right: -4px;
    min-width: 121px;
    position: relative;
  }

 .dashboard-main-podcast-creator {
    height: 20px;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }

 .dashboard-main-podcast-name {
    height: 16px;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 24px;
    white-space: nowrap;
  }

 .dashboard-main-avatars-web-7 {
    background-image: url(https://anima-uploads.s3.amazonaws.com/projects/636cb9c4a99a504c4959b85f/releases/637b6d1abce254c68246a36a/img/background-7@2x.svg);
    background-position: 50% 50%;
    background-size: cover;
    border: 1px none;
    height: 40px;
    min-width: 40px;
  }

 .dashboard-main-podcast-stats {
    height: 36px;
    margin-right: -4px;
    min-width: 76px;
    position: relative;
  }

 .dashboard-main-podcast-genre {
    height: 16px;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }

 .dashboard-main-podcast-view-count {
    height: 16px;
    left: 16px;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 20px;
    white-space: nowrap;
  }
.dashboard-main-content{
  display: flex;
  flex-direction: row;
  gap: 3vw;
}
.dashboard-main-top-podcasters-card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.dashboard-main-top-podcaster-card {
  align-items: flex-end;
  border: 1px none;
  display: flex;
  height: 40px;

  min-width: 360px;
}

.dashboard-main-podcast-title-and-badge {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 46px;
  min-height: 36px;
  width: 158px;
}

.dashboard-main-git-and-version-control {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 2px;
  min-width: 156px;
  text-align: center;
  white-space: nowrap;
}

.dashboard-main-badge-container {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  height: 16px;
  margin-right: 6px;
  min-width: 103px;
}

.dashboard-main-badge-pill-green-small {
  align-items: flex-start;
  background-color: var(--jagged-ice);
  border: 1px none;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  padding: 2px 8px;
  width: -moz-fit-content;
  width: fit-content;
}

.dashboard-main-green-small {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.dashboard-main-avatars-web-8 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/636cb9c4a99a504c4959b85f/releases/637b6d1abce254c68246a36a/img/background-8@2x.svg);
  background-position: 50% 50%;
  background-size: cover;
  border: 1px none;
  height: 40px;
  margin-bottom: -2px;
  width: 40px;
}

.dashboard-main-podcaster-name-and-email {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: -2px;
  margin-left: 17px;
  min-height: 40px;
  width: 106px;
}

.dashboard-main-podcaster-name {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 102px;
  text-align: center;
  white-space: nowrap;
}

.dashboard-main-podcaster-email {
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 1px;
  min-width: 73px;
  text-align: center;
  white-space: nowrap;
}




  /*Fonts*/
:root {
    --black:
  rgba(0, 0, 0, 1);
    --endeavour:
  rgba(0, 89, 172, 1);
    --ultramarine:
  rgba(19, 0, 134, 1);
    --eucalyptus:
  rgba(21, 137, 87, 1);
    --eerie-black:
  rgba(27, 30, 33, 1);
    --dark-blue:
  rgba(33, 0, 227, 0.22);
    --charade:
  rgba(33, 41, 52, 0.071);
    --blue:
  rgba(37, 1, 249, 1);
    --tuna:
  rgba(54, 60, 67, 1);
    --chateau-green:
  rgba(54, 179, 104, 1);
    --canary:
  rgba(67, 35, 255, 1);
    --ocean-blue-pearl:
  rgba(81, 60, 204, 1);
    --scarpa-flow:
  rgba(82, 89, 100, 1);
    --electric-violet:
  rgba(98, 0, 238, 1);
    --purple-heart:
  rgba(101, 75, 255, 1);
    --pale-sky:
  rgba(109, 119, 134, 1);
    --blue-marguerite:
  rgba(121, 98, 255, 1);
    --regent-gray:
  rgba(136, 149, 167, 1);
    --gray:
  rgba(145, 145, 145, 1);
    --cornflower:
  rgba(151, 209, 242, 1);
    --portage:
  rgba(152, 134, 255, 1);
    --delta:
  rgba(162, 162, 162, 1);
    --heather:
  rgba(184, 196, 206, 1);
    --jagged-ice:
  rgba(196, 239, 223, 1);
    --mischka:
  rgba(206, 214, 222, 1);
    --alto:
  rgba(217, 217, 217, 1);
    --pattens-blue:
  rgba(221, 238, 255, 1);
    --tahiti-gold:
  rgba(224, 131, 4, 1);
    --blue-chalk:
  rgba(233, 228, 255, 1);
    --solitude:
  rgba(234, 246, 252, 1);
    --gallery:
  rgba(237, 237, 237, 1);
    --cultured-pearl:
  rgba(245, 245, 245, 1);
    --black-squeeze:
  rgba(245, 248, 250, 1);
    --white-lilac:
  rgba(249, 247, 255, 1);
    --zircon:
  rgba(249, 248, 255, 1);
    --alabaster:
  rgba(250, 250, 250, 1);
    --amber:
  rgba(255, 196, 0, 1);
    --peach-cream:
  rgba(255, 239, 219, 1);
    --white:
  rgba(255, 255, 255, 1);

    --font-size-xs: 10px;
    --font-size-s: 12px;
    --font-size-m: 14px;
    --font-size-l: 16px;
    --font-size-xl: 18px;
    --font-size-xl2: 20px;
    --font-size-xxl2: 24px;
    --font-size-xxl22: 22px;

    --font-family-circular_std-book: "Circular Std-Book";
    --font-family-circular_std-medium: "Circular Std-Medium";
    --font-family-hero_new-medium: "Hero New-Medium";
    --font-family-hero_new-semibold: "Hero New-SemiBold";
    --font-family-montserrat: "Montserrat";
    --font-family-montserrat-black: "Montserrat-Black";
    --font-family-open_sans: "Open Sans";
    --font-family-open_sans-bold: "Open Sans-Bold";
  }



 .dashboard-main-opensans-semi-bold-eerie-black-14px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-regent-gray-12px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-main-opensans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-gray-14px {
    color: var(--gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-scarpa-flow-14px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-electric-violet-16px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-pale-sky-12px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-eerie-black-12px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-chateau-green-12px {
    color: var(--chateau-green);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-blue-16px {
    color: var(--blue);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-eucalyptus-10px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-electric-violet-18px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans) !important;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-electric-violet-20px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-heather-14px {
    color: var(--heather);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-electric-violet-14px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-purple-heart-16px {
    color: var(--purple-heart);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-tahiti-gold-12px {
    color: var(--tahiti-gold);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-regent-gray-16px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-solitude-14px {
    color: var(--solitude);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-ocean-blue-pearl-14px {
    color: var(--ocean-blue-pearl);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-tuna-14px {
    color: var(--tuna);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-scarpa-flow-22px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-xxl22);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-scarpa-flow-20px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-white-10px {
    color: var(--white);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-circularstd-medium-endeavour-10px {
    color: var(--endeavour);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-eucalyptus-12px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-cornflower-14px {
    color: var(--cornflower);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-regent-gray-14px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-circularstd-book-normal-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-circular_std-book);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-bold-scarpa-flow-24px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl2);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-regent-gray-10px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-black-squeeze-14px {
    color: var(--black-squeeze);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-bold-white-16px {
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-scarpa-flow-10px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-main-opensans-semi-bold-white-20px {
    color: var(--white);
    font-family: var(--font-family-open_sans) !important;
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-main-opensans-normal-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }