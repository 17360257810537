@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,600");
body{
  margin: 0;
  font-family:"Open Sans";
}
.dashboard-podcasts-active-link {
  cursor: pointer;
  background: rgba(67, 35, 255, 1)
}
.dashboard-podcasts-container {
  display: flex;
  gap: 30px;
}
.dashboard-podcasts-side-nav {
    background-color: rgba(20, 0, 135, 1);
    display: flex;
    flex-direction: column;
    width: 15vw;
    color: white;
    gap: 10vh;
  margin: 0;
  position: fixed;
  top: 0;
}
.dashboard-podcasts-main-content{
  margin-left: 280px;
}
.dashboard-podcasts-nav-header {
  margin-left: 15px;
    position: -webkit-sticky;
    position: sticky;
  top: 0;
}
.dashboard-podcasts-podcast-menu-and-search-bar {
  display: flex;
  gap: 50px;
}
.dashboard-podcasts-podcast-search-bar input{
 border: none;
  width: 700px;
  height: 40px;
   border-radius: 8px;
}
.dashboard-podcasts-logout {
  margin-left: 25px;
    position: -webkit-sticky;
    position: sticky;
  top: 0;
}
.dashboard-podcasts-logout.dashboard-podcasts-align-center{
  font-family: "Open Sans";
  gap: 5px;
}
.dashboard-podcasts-flex-row {
    display: flex;
    flex-direction: row;
}
.dashboard-podcasts-overview-links,.dashboard-podcasts-tool-links {
  display: flex;
  flex-direction: column;
  /*! padding-left: 20px; */
  color: white;
}
.dashboard-podcasts-hover:hover {
  background: rgba(67, 35, 255, 1);
}
.dashboard-podcasts-hover{
  cursor: pointer;
 
}
.dashboard-podcasts-overview-links div,.dashboard-podcasts-tool-links div {
    display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.dashboard-podcasts-banner {
  align-items: center;
  justify-content: center;
  background-color:#f7fbff;
  border: 1px none;
  display: flex;
  gap: 8px;
  height: 40px;
  padding: 10px 16px;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 8;
}
.dashboard-podcasts-align-text-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
}
.dashboard-podcasts-banner-title {
    color:
  #051b30;
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }


 .dashboard-podcasts-banner-icon-div {
    border: 1px none;
    height: 16px;
    min-width: 16px;
    position: relative;
  }

 .dashboard-podcasts-banner-img {
    height: 13px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 13px;
  }

 .dashboard-podcasts-banner-text {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 0
  }
 .dashboard-podcasts-podcast-menu {
    align-items: center;
    border: 1px none;
    display: flex;
    gap: 32px;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 9;
  }
.dashboard-podcasts-podcast-menu div{
  cursor: pointer
}
 .dashboard-podcasts-active {
    align-items: flex-start;
    background-color:
#2100e338;
    border: 1px none;
    border-radius: 16px;
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-podcasts-all-podcasts {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    text-align: center;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-podcasts-podcast-menu-item {
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-podcasts-podcast-search-bar {
    align-items: center;
    border: 1px solid;
    border-color: var(--heather);
    border-radius: 8px;
    display: flex;
    gap: 24px;
    padding: 0px 1px 0px 8px;
    width: 315px;
    z-index: 12;
  }
 .dashboard-podcasts-create-podcast-button {
    align-items: center;
    border: 1px none;
    border-radius: 6px;
    display: flex;
    gap: 8px;
    height: 40px;
    justify-content: center;
    padding: 12px 24px;
    background: #140087;
    color: #f5f8fa;
    font-family: var(--font-family-hero_new-medium);
    font-size: var(--font-size-m);
    font-weight: 500;
    line-height: 16px;
    margin-top: 7px;
    transition: all 0.2s ease;
    cursor: pointer;
  }
.dashboard-podcasts-create-podcast-button:hover{
  transform: scale(1.1)
}
 .dashboard-podcasts-all-podcast-header {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    height: 24px;
    letter-spacing: 0;
    line-height: 24px;
    white-space: nowrap;
    z-index: 11;
  }
.dashboard-podcasts-podcast-header-and-create-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
}
table{
  width: 100%;
  text-align: center
}
th{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  td{
    padding: 15px;
  }
  tr.header-row th {
    background: #F5F5F5;
  }
.dashboard-podcasts-header-row{
    background: #F5F5F5;
    height: 40px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #8895A7;
  }
.dashboard-podcasts-table-data:hover{
  transform: scale(1.1)
}
.dashboard-podcasts-table-data{
  transition: all 0.5s ease;
  cursor: pointer;
}
.dashboard-podcasts-podcast-title-info {
  align-items: center;
  display: flex;
  gap: 16px;
  height: 40px;
  min-width: 50px;
}
.dashboard-podcasts-name-and-creator {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 40px;
  }
 .dashboard-podcasts-podcast-name {
    height: 20px;
    letter-spacing: 0;
    line-height: 20px;
    min-width: 120px;
    text-align: center;
    white-space: nowrap;
  }
 .dashboard-podcasts-podcast-creator {
    height: 16px;
    letter-spacing: 0;
    line-height: 16px;
    min-width: 65px;
    text-align: center;
    white-space: nowrap;
  }
 .dashboard-podcasts-badge-pill-yellow {
    align-items: center;
    background-color: var(--peach-cream);
    border: 1px none;
    border-radius: 18px;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-left: 5px;
    padding: 4px 8px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-podcasts-badge-pill-green {
    align-items: center;
    background-color: var(--jagged-ice);
    border: 1px none;
    border-radius: 18px;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 4px 8px;
    width: -moz-fit-content;
    width: fit-content;
  }
 .dashboard-podcasts-status-text {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
  }
.dashboard-podcasts-status-badge{
  padding-left: 40px;
}
 .dashboard-podcasts-release-date {
    height: 20px;
    letter-spacing: 0;
    line-height: 20px;
    min-width: 56px;
    text-align: center;
    white-space: nowrap;
  }
 .dashboard-podcasts-audience-satisfaction-container{
    align-items: center;
    background-color: #f5f3ff;
    border: 1px none;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
     
    z-index: 2;
    height: 300px
    
  }
.dashboard-podcasts-based-on-likes-dislikes-text {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 30px;
  min-width: 176px;
  white-space: nowrap;
}
 .dashboard-podcasts-audience-satisfaction-text{
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 8px;
    white-space: nowrap;
    margin-bottom: 0;
  }
 .dashboard-podcasts-audience-satisfaction-content-div {
    height: 128px;
    margin-left: 1px;
    margin-top: 17px;
    position: relative;
    width: 280px;
  }

  path {
    stroke-linecap: round;
    stroke-width: 6;
  }

  path.grey {
    stroke: #e7e7e8;
  }

  path.blue {
    stroke: url(#gradient);
    stroke-dasharray: 198;
    stroke-dashoffset: 198;
    animation: dash 3s ease-out forwards;
  }
 .dashboard-podcasts-smiley-icon {
    height: 44px;
    width: 44px;
  }
 .dashboard-podcasts-newsletter-frame{
    align-items: flex-start;
    background-color: var(--ultramarine);
    border: 1px none;
    border-radius: 8px;
    display: flex;
    height: 358px;
    left: 1044px;
    overflow: hidden;
    /*! position: fixed; */
    top: 400px;
    width: 363px;
    z-index: 3;
  }
 .dashboard-podcasts-newsletter-content {
    align-items: flex-start;
    background-color: var(--ultramarine);
    display: flex;
    flex-direction: column;
    min-height: 358px;
    padding: 27px 17px;
    width: 363px;
  }
 .dashboard-podcasts-newsletter-heading {
    letter-spacing: 0;
    line-height: 19px;
    margin-left: 15px;
    white-space: nowrap;
  }
 .dashboard-podcasts-newsletter-message {
    align-self: center;
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl2);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    margin-right: 22px;
    margin-top: 24px;
    min-height: 90px;
    min-width: 277px;
  }
 .dashboard-podcasts-subscribe-text{
    align-self: flex-end;
    color:
    #b6a9ff;
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 16px;
    min-height: 72px;
    min-width: 314px;
  }
 .dashboard-podcasts-learn-more-text {
    letter-spacing: 0;
    line-height: 19px;
    margin-left: 15px;
    margin-top: 64px;
    white-space: nowrap;
  }
.dashboard-podcasts-sub-contents {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
#progress{
  display: flex;
  align-items: center;
  justify-content: center
}

  /*Rating progress*/
  

/*Fonts*/
:root {
    --black:
  rgba(0, 0, 0, 1);
    --endeavour:
  rgba(0, 89, 172, 1);
    --ultramarine:
  rgba(19, 0, 134, 1);
    --eucalyptus:
  rgba(21, 137, 87, 1);
    --eerie-black:
  rgba(27, 30, 33, 1);
    --dark-blue:
  rgba(33, 0, 227, 0.22);
    --charade:
  rgba(33, 41, 52, 0.071);
    --blue:
  rgba(37, 1, 249, 1);
    --tuna:
  rgba(54, 60, 67, 1);
    --chateau-green:
  rgba(54, 179, 104, 1);
    --canary:
  rgba(67, 35, 255, 1);
    --ocean-blue-pearl:
  rgba(81, 60, 204, 1);
    --scarpa-flow:
  rgba(82, 89, 100, 1);
    --electric-violet:
  rgba(98, 0, 238, 1);
    --purple-heart:
  rgba(101, 75, 255, 1);
    --pale-sky:
  rgba(109, 119, 134, 1);
    --blue-marguerite:
  rgba(121, 98, 255, 1);
    --regent-gray:
  rgba(136, 149, 167, 1);
    --gray:
  rgba(145, 145, 145, 1);
    --cornflower:
  rgba(151, 209, 242, 1);
    --portage:
  rgba(152, 134, 255, 1);
    --delta:
  rgba(162, 162, 162, 1);
    --heather:
  rgba(184, 196, 206, 1);
    --jagged-ice:
  rgba(196, 239, 223, 1);
    --mischka:
  rgba(206, 214, 222, 1);
    --alto:
  rgba(217, 217, 217, 1);
    --pattens-blue:
  rgba(221, 238, 255, 1);
    --tahiti-gold:
  rgba(224, 131, 4, 1);
    --blue-chalk:
  rgba(233, 228, 255, 1);
    --solitude:
  rgba(234, 246, 252, 1);
    --gallery:
  rgba(237, 237, 237, 1);
    --cultured-pearl:
  rgba(245, 245, 245, 1);
    --black-squeeze:
  rgba(245, 248, 250, 1);
    --white-lilac:
  rgba(249, 247, 255, 1);
    --zircon:
  rgba(249, 248, 255, 1);
    --alabaster:
  rgba(250, 250, 250, 1);
    --amber:
  rgba(255, 196, 0, 1);
    --peach-cream:
  rgba(255, 239, 219, 1);
    --white:
  rgba(255, 255, 255, 1);

    --font-size-xs: 10px;
    --font-size-s: 12px;
    --font-size-m: 14px;
    --font-size-l: 16px;
    --font-size-xl: 18px;
    --font-size-xl2: 20px;
    --font-size-xxl2: 24px;
    --font-size-xxl22: 22px;

    --font-family-circular_std-book: "Circular Std-Book";
    --font-family-circular_std-medium: "Circular Std-Medium";
    --font-family-hero_new-medium: "Hero New-Medium";
    --font-family-hero_new-semibold: "Hero New-SemiBold";
    --font-family-montserrat: "Montserrat";
    --font-family-montserrat-black: "Montserrat-Black";
    --font-family-open_sans: "Open Sans";
    --font-family-open_sans-bold: "Open Sans-Bold";
  }



 .dashboard-podcasts-opensans-semi-bold-eerie-black-14px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-regent-gray-12px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-gray-14px {
    color: var(--gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-electric-violet-16px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-pale-sky-12px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-eerie-black-12px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-chateau-green-12px {
    color: var(--chateau-green);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-blue-16px {
    color: var(--blue);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-eucalyptus-10px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-electric-violet-18px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-electric-violet-20px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-heather-14px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-electric-violet-14px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-purple-heart-16px {
    color: var(--purple-heart);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px {
    color: var(--tahiti-gold);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-regent-gray-16px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-solitude-14px {
    color: var(--solitude);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-ocean-blue-pearl-14px {
    color: var(--ocean-blue-pearl);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-tuna-14px {
    color: var(--tuna);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-scarpa-flow-22px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl22);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-scarpa-flow-20px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-white-10px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-circularstd-medium-endeavour-10px {
    color: var(--endeavour);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-eucalyptus-12px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-cornflower-14px {
    color: var(--cornflower);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-regent-gray-14px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-circularstd-book-normal-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-circular_std-book);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-bold-scarpa-flow-24px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl2);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-regent-gray-10px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-black-squeeze-14px {
    color: var(--black-squeeze);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-bold-white-16px {
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-scarpa-flow-10px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 400;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-semi-bold-white-20px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .dashboard-podcasts-opensans-normal-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }