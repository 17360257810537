@import url("https://fonts.googleapis.com/css?family=Poppins:500,600|Open+Sans:400");
@font-face {
  font-family: "Times New Roman-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/TimesNewRoman-Bold");
}

.podcasts-upload-option-screen a {
  display: contents;
  text-decoration: none;
}

.podcasts-upload-option-container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.podcasts-upload-option-container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.podcasts-upload-option-valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.podcasts-upload-option-frame-8133 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  left: 0;
  padding: 12px 32px;
  position: absolute;
  top: 658px;
  transition: all 0.2s ease;
  width: 256px;
}

.podcasts-upload-option-frame-8133:hover {
  background-color: 
#4323ff;
}

.podcasts-upload-option-icon {
  height: 24px;
  min-width: 24px;
}

.podcasts-upload-option-logout {
  letter-spacing: 0;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
}

.podcasts-upload-option-frame-8133.frame-8134 {
  top: 376px;
}

.podcasts-upload-option-frame-1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  left: 0;
  padding: 12px 32px;
  position: absolute;
  top: 169px;
  transition: all 0.2s ease;
  width: 256px;
}

.podcasts-upload-option-frame-1:hover {
  background-color: 
#4323ff;
}

.podcasts-upload-option-icoutline-space-dashboard {
  height: 24px;
  min-width: 24px;
}

.podcasts-upload-option-dash-board {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.5px;
  width: -moz-fit-content;
  width: fit-content;
}

.podcasts-upload-option-frame-1.frame-8136 {
  top: 328px;
}

.podcasts-upload-option-frame-8131 {
  align-items: center;
  background-color: var(--canary);
  border-color: var(--portage);
  border-left-style: solid;
  border-left-width: 4px;
  display: flex;
  gap: 12px;
  left: 0;
  padding: 12px 32px;
  position: absolute;
  top: 217px;
  width: 256px;
}

.podcasts-upload-option-iconoutlinesound {
  height: 24px;
  min-width: 24px;
}

.podcasts-upload-option-my-podcasts {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.5px;
  width: -moz-fit-content;
  width: fit-content;
}

.podcasts-upload-option-component-17 {
  align-items: flex-start;
  background-color: 
#fafbfc;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 65px;
  min-height: 456px;
  padding: 33.7px 123px;
  transition: all 0.2s ease;
  width: 464px;
}

.podcasts-upload-option-component-17:hover {
  transform: scale(1.2);
}

.podcasts-upload-option-bimic-fill {
  height: 200px;
  width: 200px;
}

.podcasts-upload-option-record {
  align-self: center;
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin-right: 18px;
  min-width: 115px;
  white-space: nowrap;
}

.podcasts-upload-option-upload-option {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  gap: 43px;
  height: 772px;
  width: 1262px;
}

.podcasts-upload-option-sidebar {
  align-items: flex-start;
  display: flex;
  height: 757px;
  min-width: 256px;
}

.podcasts-upload-option-overlap-group2 {
  height: 781px;
  margin-top: -13px;
  position: relative;
  width: 256px;
}

.podcasts-upload-option-rectangle-1 {
  background-color: var(--ultramarine);
  height: 768px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 256px;
}

.podcasts-upload-option-version-100 {
  height: 24px;
  left: 35px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 733px;
  white-space: nowrap;
}

.podcasts-upload-option-overview {
  left: 33px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 140px;
}

.podcasts-upload-option-tools {
  left: 33px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 296px;
}

.podcasts-upload-option-frame-2 {
  background-color: var(--ocean-blue-pearl);
  height: 58px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 256px;
}

.podcasts-upload-option-frame-8146 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 70px;
  left: 25px;
  min-width: 158px;
  position: absolute;
  top: 0;
}

.podcasts-upload-option-overlap-group {
  align-self: flex-end;
  height: 57px;
  position: relative;
  width: 76px;
}

.podcasts-upload-option-celecast {
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 36px;
}

.podcasts-upload-option-rectangle-99 {
  height: 53px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 76px;
}

.podcasts-upload-option-creator {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 14px;
  min-width: 70px;
  white-space: nowrap;
}

.podcasts-upload-option-flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-top: 67px;
  min-height: 705px;
  width: 963px;
}

.podcasts-upload-option-title {
  color: var(--scarpa-flow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.podcasts-upload-option-overlap-group3 {
  height: 642px;
  position: relative;
  width: 963px;
}

.podcasts-upload-option-copyright-c-celeca {
  color: 
#b8bec9;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 500;
  left: 617px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 623px;
  white-space: nowrap;
}

.podcasts-upload-option-frame-8148 {
  align-items: flex-start;
  display: flex;
  gap: 57px;
  height: 642px;
  left: 0;
  min-width: 878px;
  padding: 28px 24px;
  position: absolute;
  top: 0;
}

.podcasts-upload-option-overlap-group-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 398px;
  width: 273px;
}

.podcasts-upload-option-overlap-group-1 {
  align-items: flex-start;
  border: 2px solid;
  border-color: var(--concrete);
  display: flex;
  height: 200px;
  justify-content: flex-end;
  margin-left: 2px;
  min-width: 271px;
  padding: 16px 21px;
}

.podcasts-upload-option-frame-70 {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 46px;
  transition: all 0.2s ease;
  width: -moz-fit-content;
  width: fit-content;
}

.podcasts-upload-option-frame-70:hover,
.podcasts-upload-option-frame-71:hover {
  transform: scale(1.2);
}

.podcasts-upload-option-fa-solidcloud-upload-alt {
  height: 80px;
  min-width: 100px;
}

.podcasts-upload-option-choose-file-to-upload {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.podcasts-upload-option-overlap-group1 {
  align-items: flex-start;
  border: 2px solid;
  border-color: var(--concrete);
  display: flex;
  height: 174px;
  min-width: 273px;
  padding: 5px 21px;
}

.podcasts-upload-option-frame-71 {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 31px;
  transition: all 0.2s ease;
  width: -moz-fit-content;
  width: fit-content;
}

.podcasts-upload-option-material-symbolsdriv {
  height: 100px;
  min-width: 100px;
}

.podcasts-upload-option-choose-from-manager {
  color: 
#4637c6;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}
:root {
    --black: 
  rgba(0, 0, 0, 1);
    --endeavour: 
  rgba(0, 89, 172, 1);
    --orient: 
  rgba(8, 94, 122, 1);
    --azure-radiance: 
  rgba(12, 127, 233, 1);
    --ultramarine: 
  rgba(19, 0, 134, 1);
    --eucalyptus: 
  rgba(21, 137, 87, 1);
    --eerie-black-2: 
  rgba(27, 28, 29, 1);
    --eerie-black: 
  rgba(27, 30, 33, 1);
    --dark-blue: 
  rgba(33, 0, 227, 0.22);
    --charade: 
  rgba(33, 41, 52, 0.071);
    --blue: 
  rgba(37, 1, 249, 1);
    --tuna: 
  rgba(54, 60, 67, 1);
    --chateau-green: 
  rgba(54, 179, 104, 1);
    --canary: 
  rgba(67, 35, 255, 1);
    --picton-blue: 
  rgba(67, 210, 249, 1);
    --ocean-blue-pearl: 
  rgba(81, 60, 204, 1);
    --scarpa-flow: 
  rgba(82, 89, 100, 1);
    --electric-violet: 
  rgba(98, 0, 238, 1);
    --purple-heart: 
  rgba(101, 75, 255, 1);
    --pale-sky: 
  rgba(109, 119, 134, 1);
    --blue-marguerite: 
  rgba(121, 98, 255, 1);
    --regent-gray: 
  rgba(136, 149, 167, 1);
    --gray: 
  rgba(145, 145, 145, 1);
    --portage-2: 
  rgba(151, 133, 255, 1);
    --cornflower: 
  rgba(151, 209, 242, 1);
    --portage: 
  rgba(152, 134, 255, 1);
    --delta: 
  rgba(162, 162, 162, 1);
    --heather: 
  rgba(184, 196, 206, 1);
    --french-pass: 
  rgba(189, 243, 252, 1);
    --jagged-ice: 
  rgba(196, 239, 223, 1);
    --mischka: 
  rgba(206, 214, 222, 1);
    --alto: 
  rgba(217, 217, 217, 1);
    --pattens-blue: 
  rgba(221, 238, 255, 1);
    --tahiti-gold: 
  rgba(224, 131, 4, 1);
    --blue-chalk: 
  rgba(233, 228, 255, 1);
    --solitude: 
  rgba(234, 246, 252, 1);
    --gallery: 
  rgba(237, 237, 237, 1);
    --concrete: 
  rgba(242, 243, 245, 1);
    --cultured-pearl: 
  rgba(245, 245, 245, 1);
    --black-squeeze: 
  rgba(245, 248, 250, 1);
    --white-lilac: 
  rgba(249, 247, 255, 1);
    --zircon: 
  rgba(249, 248, 255, 1);
    --alabaster: 
  rgba(250, 250, 250, 1);
    --amber: 
  rgba(255, 196, 0, 1);
    --peach-cream: 
  rgba(255, 239, 219, 1);
    --white: 
  rgba(255, 255, 255, 1);
  
    --font-size-xs: 10px;
    --font-size-s: 12px;
    --font-size-m: 14px;
    --font-size-l: 16px;
    --font-size-xl: 18px;
    --font-size-xl2: 20px;
    --font-size-xxl2: 24px;
    --font-size-xxl22: 22px;
  
    --font-family-circular_std-book: "Circular Std-Book";
    --font-family-circular_std-medium: "Circular Std-Medium";
    --font-family-hero_new-bold: "Hero New-Bold";
    --font-family-hero_new-medium: "Hero New-Medium";
    --font-family-hero_new-semibold: "Hero New-SemiBold";
    --font-family-montserrat: "Montserrat";
    --font-family-montserrat-black: "Montserrat-Black";
    --font-family-open_sans: "Open Sans";
    --font-family-open_sans-bold: "Open Sans-Bold";
    --font-family-open_sans-semibold: "Open Sans-SemiBold";
    --font-family-poppins: "Poppins";
    --font-family-times_new_roman-bold: "Times New Roman-Bold";
  }
  
  
  
 .podcasts-upload-option-opensans-semi-bold-eerie-black-14px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-white-20px {
    color: var(--white);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-regent-gray-12px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-regent-gray-14px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-ocean-blue-pearl-14px {
    color: var(--ocean-blue-pearl);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-chateau-green-12px {
    color: var(--chateau-green);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-gray-14px {
    color: var(--gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-14px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-white-10px {
    color: var(--white);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-electric-violet-16px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-pale-sky-12px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-poppins-medium-amber-18px {
    color: var(--amber);
    font-family: var(--font-family-poppins);
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-black-24px {
    color: var(--black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl2);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-eerie-black-12px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-chateau-green-12px {
    color: var(--chateau-green);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-blue-16px {
    color: var(--blue);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-eerie-black-14px {
    color: var(--eerie-black);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-electric-violet-16px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-electric-violet-14px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-eucalyptus-10px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-electric-violet-18px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-electric-violet-20px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-heather-14px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-regent-gray-10px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-electric-violet-14px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-black-14px {
    color: var(--black);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-timesnewroman-bold-white-18px {
    color: var(--white);
    font-family: var(--font-family-times_new_roman-bold);
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-purple-heart-16px {
    color: var(--purple-heart);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-montserrat-black-orient-12px {
    color: var(--orient);
    font-family: var(--font-family-montserrat-black);
    font-size: var(--font-size-s);
    font-weight: 900;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-tahiti-gold-12px {
    color: var(--tahiti-gold);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-black-24px {
    color: var(--black);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-xxl2);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-regent-gray-16px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-solitude-14px {
    color: var(--solitude);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-black-14px {
    color: var(--black);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-electric-violet-18px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans-semibold);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-ocean-blue-pearl-14px {
    color: var(--ocean-blue-pearl);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-tuna-14px {
    color: var(--tuna);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-circularstd-book-normal-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-circular_std-book);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-22px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl22);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-eucalyptus-12px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-eucalyptus-10px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-20px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-20px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-pale-sky-14px {
    color: var(--pale-sky);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-ocean-blue-pearl-14px {
    color: var(--ocean-blue-pearl);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-white-10px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-heather-16px {
    color: var(--heather);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-heather-14px {
    color: var(--heather);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-electric-violet-20px {
    color: var(--electric-violet);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-circularstd-medium-endeavour-10px {
    color: var(--endeavour);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
 .podcasts-upload-option-montserrat-black-orient-12px {
    color: var(--orient);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-s);
    font-weight: 900;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-black-squeeze-14px {
    color: var(--black-squeeze);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-scarpa-flow-14px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-eucalyptus-12px {
    color: var(--eucalyptus);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-heather-14px {
    color: var(--heather);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-cornflower-14px {
    color: var(--cornflower);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-tahiti-gold-12px {
    color: var(--tahiti-gold);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-s);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-regent-gray-14px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-scarpa-flow-24px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-xxl2);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-heronew-semi-bold-pale-sky-24px {
    color: var(--pale-sky);
    font-family: var(--font-family-hero_new-semibold);
    font-size: var(--font-size-xxl2);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-22px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-xxl22);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-circularstd-book-normal-scarpa-flow-12px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-circular_std-book);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-scarpa-flow-24px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xxl2);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-eerie-black-13px {
    color: var(--eerie-black-2);
    font-family: var(--font-family-open_sans);
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-blue-16px {
    color: var(--blue);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-16px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-l);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-regent-gray-10px {
    color: var(--regent-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-black-squeeze-14px {
    color: var(--black-squeeze);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-white-16px {
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-bold-white-16px {
    font-family: var(--font-family-open_sans-bold);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-scarpa-flow-10px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xs);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-scarpa-flow-14px {
    color: var(--scarpa-flow);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-white-20px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-xl2);
    font-weight: 600;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-normal-white-16px {
    color: var(--white);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
 .podcasts-upload-option-heronew-bold-orient-64px {
    color: var(--orient);
    font-family: var(--font-family-hero_new-bold);
    font-size: 64px;
    font-weight: 700;
    font-style: normal;
  }
 .podcasts-upload-option-opensans-semi-bold-ocean-blue-pearl-14px {
    color: var(--ocean-blue-pearl);
    font-family: var(--font-family-open_sans-semibold);
    font-size: var(--font-size-m);
    font-weight: 600;
    font-style: normal;
  }