@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600|Roboto:400,500|Poppins:500");
@font-face {
  font-family: "Hero New-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/HeroNew-Bold") format("opentype");
}
@font-face {
  font-family: "Times New Roman-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/TimesNewRoman-Bold");
}
body {
  margin: 0;
}

.edit-profile-screen a {
  display: contents;
  text-decoration: none;
}

.edit-profile-container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.edit-profile-container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.edit-profile-valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.edit-profile-profile-edit {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  height: 922px;
  position: relative;
  width: 100vw;
}

.edit-profile-sidebar {
  align-items: flex-start;
  background-color: var(--ultramarine);
  display: flex;
  flex-direction: column;
  min-height: 922px;
  position: relative;
  width: 256px;
  z-index: 2;
}

.edit-profile-overlap-group3 {
  align-items: flex-start;
  background-color: var(--ocean-blue-pearl);
  display: flex;
  gap: 12px;
  height: 71px;
  min-width: 256px;
  padding: 0 30px;
}

.edit-profile-overlap-group {
  height: 70px;
  position: relative;
  width: 76px;
}

.edit-profile-celecast {
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 49px;
}

.edit-profile-rectangle-99 {
  height: 66px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 76px;
}

.edit-profile-creator {
  align-self: center;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 13.36px;
  min-width: 70px;
  white-space: nowrap;
}

.edit-profile-overview {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 33px;
  margin-top: 84px;
  min-height: 23px;
  width: 69px;
}

.edit-profile-my-podcasts {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  height: 58px;
  margin-top: 5px;
  padding: 12px 32px;
  transition: all 0.2s ease;
  width: 256px;
}

.edit-profile-my-podcasts:hover {
  background-color: 
#4323ff;
}

.edit-profile-icon {
  height: 24px;
  min-width: 24px;
}

.edit-profile-my-podcasts-1,
.edit-profile-profile-1 {
  letter-spacing: 0;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
}

.edit-profile-tools {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 33px;
  margin-top: 38px;
  min-height: 23px;
  width: 45px;
}

.edit-profile-profile {
  align-items: center;
  background-color: var(--canary);
  border-color: var(--portage-2);
  border-left-style: solid;
  border-left-width: 4px;
  display: flex;
  gap: 12px;
  height: 58px;
  margin-top: 16px;
  padding: 12px 32px;
  width: 256px;
}

.edit-profile-version-100 {
  height: 29px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 35px;
  margin-top: 24px;
  text-align: center;
  width: 97px;
}

.edit-profile-top-nav-bar-container {
  align-items: flex-start;
  box-shadow: 0px 4px 8px 
#032f3c24;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 81px;
  left: 256px;
  padding: 0px 228px 0px 0px;
  position: fixed;
  width: 100vw;
  z-index: 3;
}

.edit-profile-overlap-group4 {

  margin-left: 16px;
  margin-top: 125px;
  position: relative;
  width: 971px;
  z-index: 1;
}

.edit-profile-flex-row {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--mischka);
  border-radius: 8px;
  display: flex;
  gap: 43px;
  height: 761px;
  left: 270px;
  min-width: 983px;
  padding: 24px 25px;
  position: absolute;
  top: 0;
}

.edit-profile-flex-col {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 694px;
  position: relative;
  width: 251px;
  top:20px;
}
.edit-profile-flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-profile-overlap-group1 {
  align-items: flex-start;
  align-self: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-right: 6px;
  min-height: 220px;
  position: relative;
  width: 231px;
}

.edit-profile-lf {
  letter-spacing: -0.32px;
  line-height: 68px;
  margin-left: 6px;
  margin-top: 65px;
  white-space: nowrap;
}

.edit-profile-section-bleed-1 {
  height: 1px;
  margin-top: 45px;
  object-fit: cover;
  width: 198px;
}

.edit-profile-group-4276 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  gap: 8px;
  height: 12px;
  margin-top: 16px;
  min-width: 244px;
}

.edit-profile-section-title-2 {
  letter-spacing: 0;
  line-height: 12px;
  min-width: 36px;
  white-space: nowrap;
}

.edit-profile-section-bleed-2 {
  height: 1px;
  margin-bottom: 2px;
  object-fit: cover;
  width: 198px;
}

.edit-profile-flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 13px;
  min-height: 536px;
  width: 606px;
}

.edit-profile-flex-row-1 {
  align-items: center;
  display: flex;
  height: 80px;
  margin-left: 2.01px;
  min-width: 601px;
  position: relative;
  gap: 55px;
  justify-content: center;
}

.edit-profile-flex-row-2 {
  align-items: center;
  display: flex;
  gap: 256px;
  height: 79px;
  margin-left: 1.01px;
  margin-top: 6px;
  min-width: 605px;
  position: relative;
}

.edit-profile-about {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  margin-left: 0.01px;
  margin-top: 31px;
  width: -moz-fit-content;
  width: fit-content;
}

.edit-profile-iconboldprofile-circle {
  height: 20px;
  min-width: 20px;
}

.edit-profile-about-1 {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.edit-profile-overlap-group2 {
  align-items: flex-start;
  background-image: url(../../../assets/dashboardAssets/line-1.svg);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 3px;
  margin-top: 4px;
  min-width: 603px;
  padding: 0 3px;
}

.edit-profile-rectangle-3135 {
  height: 2px;
  width: 64px;
}

.edit-profile-flex-row-3 {
  align-items: center;
  display: flex;
  gap: 54px;
  height: 271px;
  margin-left: 0.01px;
  margin-top: 42px;
  min-width: 426px;
}

.edit-profile-flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 271px;
  position: relative;
  width: 202px;
  margin-top: 40px;
  gap: 10px;
}

.edit-profile-contact-information {
  letter-spacing: 0;
  line-height: 12px;
  white-space: nowrap;
}

.edit-profile-basic-information {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 65px;
  white-space: nowrap;
}

.edit-profile-text-input-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-bottom: 30px;
  min-height: 167px;
  position: relative;
  width: 200px;
  margin-top: 0;
}

.edit-profile-frame-1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  margin-top: 17px;
  padding: 12px 32px;
  transition: all 0.2s ease;
  width: 256px;
}

.edit-profile-frame-1:hover {
  background-color: 
#4323ff;
}

.edit-profile-icoutline-space-dashboard {
  height: 24px;
  min-width: 24px;
}

.edit-profile-dash-board {
  letter-spacing: 0;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
}

.edit-profile-frame-1.settings {
  margin-top: 3px;
}

.edit-profile-frame-1.logout {
  margin-top: 298px;
}

.edit-profile-top-nav-bar-content {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  gap: 127px;
  height: 81px;
  margin-right: -230px;
  padding: 16px 0px 16px 22px;
  width: 1005px;
}

.edit-profile-profile-header {
  align-items: center;
  display: flex;
  gap: 128px;
  height: 45px;
  position: relative;
  width: 120px;
}

.edit-profile-searchbox {
  align-items: flex-start;
  display: flex;
  gap: 50px;
  position: relative;
  width: 430px;
}

.edit-profile-section-label {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  margin-right: -98px;
  padding: 0px 8px;
  width: -moz-fit-content;
  width: fit-content;
}

.edit-profile-label {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  width: 202px;
}

.edit-profile-text-field {
  height: 45px;
}

.edit-profile-button {
  align-self: flex-end;
  border-radius: 4px;
  height: 43px;
}
.edit-profile-save-button {
  margin-top: 60px;
}
.edit-profile-cancel-button {
  margin-left: 55px;
}
.edit-profile-edit-button {
  margin-top: 5px;
}
.edit-profile-add-work-button {
  margin-top: 20px;
}
.edit-profile-add-skills-button {
  margin-top: 100px;
}
.edit-profile-text-input {
  height: 79px;
  margin-left: 7px;
  margin-top: 20px;
}

.edit-profile-textarea {
  height: 94px;
  margin-top: 9px;
}

.edit-profile-button {
  border-radius: 4px;
  height: 37px;
  margin-left: 7px;
  margin-top: 36px;
}

.edit-profile-button {
  border-radius: 4px;
  height: 37px;
  margin-left: 7px;
  margin-top: 88px;
}

.edit-profile-text-input {
  align-self: flex-start;
  height: 79px;
}

.edit-profile-text-input {
  height: 79px;
  margin-left: 24px;
}

.edit-profile-button {
  border-radius: 4px;
  height: 43px;
  margin-bottom: 7px;
  margin-left: 69px;
}

.edit-profile-text-input {
  height: 79px;
}

.edit-profile-button {
  border-radius: 4px;
  height: 43px;
  margin-top: 10px;
}

.edit-profile-text-input {
  height: 79px;
  margin-left: 2px;
  margin-top: 15px;
}

.edit-profile-text-input {
  height: 79px;
  margin-left: 1px;
  margin-top: 9px;
}

.edit-profile-text-input {
  height: 79px;
}

.edit-profile-text-input {
  height: 79px;
}

.edit-profile-date-picker {
  height: 60px;
  left: 589px;
  position: absolute;
  top: 620px;
}



.edit-profile-native-select {
  height: 60px;
  left: 297px;
  position: absolute;
  top: 624px;
}
.genre-select {
  height: 60px;
  left: 845px;
  position: absolute;
  top: 620px;
}

.edit-profile-main-frame {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  height: 56px;
  left: 1048px;
  min-width: 136px;
  position: fixed;
  top: 17px;
  z-index: 4;
}

.edit-profile-fab {
  border-radius: 24px;
  height: 48px;
}

.edit-profile-avatar {
  border-radius: 27.5px;
  height: 55px;
}
:root {
  --black: 
rgba(0, 0, 0, 1);
  --endeavour: 
rgba(0, 89, 172, 1);
  --orient: 
rgba(8, 94, 122, 1);
  --azure-radiance: 
rgba(12, 127, 233, 1);
  --ultramarine: 
rgba(19, 0, 134, 1);
  --eucalyptus: 
rgba(21, 137, 87, 1);
  --eerie-black-2: 
rgba(27, 28, 29, 1);
  --eerie-black: 
rgba(27, 30, 33, 1);
  --dark-blue: 
rgba(33, 0, 227, 0.22);
  --charade: 
rgba(33, 41, 52, 0.071);
  --blue: 
rgba(37, 1, 249, 1);
  --tuna: 
rgba(54, 60, 67, 1);
  --chateau-green: 
rgba(54, 179, 104, 1);
  --canary: 
rgba(67, 35, 255, 1);
  --picton-blue: 
rgba(67, 210, 249, 1);
  --ocean-blue-pearl: 
rgba(81, 60, 204, 1);
  --scarpa-flow: 
rgba(82, 89, 100, 1);
  --electric-violet: 
rgba(98, 0, 238, 1);
  --purple-heart: 
rgba(101, 75, 255, 1);
  --pale-sky: 
rgba(109, 119, 134, 1);
  --blue-marguerite: 
rgba(121, 98, 255, 1);
  --regent-gray: 
rgba(136, 149, 167, 1);
  --gray: 
rgba(145, 145, 145, 1);
  --portage-2: 
rgba(151, 133, 255, 1);
  --cornflower: 
rgba(151, 209, 242, 1);
  --portage: 
rgba(152, 134, 255, 1);
  --delta: 
rgba(162, 162, 162, 1);
  --heather: 
rgba(184, 196, 206, 1);
  --french-pass: 
rgba(189, 243, 252, 1);
  --jagged-ice: 
rgba(196, 239, 223, 1);
  --mischka: 
rgba(206, 214, 222, 1);
  --alto: 
rgba(217, 217, 217, 1);
  --pattens-blue: 
rgba(221, 238, 255, 1);
  --tahiti-gold: 
rgba(224, 131, 4, 1);
  --blue-chalk: 
rgba(233, 228, 255, 1);
  --solitude: 
rgba(234, 246, 252, 1);
  --gallery: 
rgba(237, 237, 237, 1);
  --concrete: 
rgba(242, 243, 245, 1);
  --cultured-pearl: 
rgba(245, 245, 245, 1);
  --black-squeeze: 
rgba(245, 248, 250, 1);
  --white-lilac: 
rgba(249, 247, 255, 1);
  --zircon: 
rgba(249, 248, 255, 1);
  --alabaster: 
rgba(250, 250, 250, 1);
  --amber: 
rgba(255, 196, 0, 1);
  --peach-cream: 
rgba(255, 239, 219, 1);
  --white: 
rgba(255, 255, 255, 1);

  --font-size-xs: 10px;
  --font-size-s: 12px;
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 18px;
  --font-size-xl2: 20px;
  --font-size-xxl2: 24px;
  --font-size-xxl22: 22px;

  --font-family-circular_std-book: "Circular Std-Book";
  --font-family-circular_std-medium: "Circular Std-Medium";
  --font-family-hero_new-bold: "Hero New-Bold";
  --font-family-hero_new-medium: "Hero New-Medium";
  --font-family-hero_new-semibold: "Hero New-SemiBold";
  --font-family-montserrat: "Montserrat";
  --font-family-montserrat-black: "Montserrat-Black";
  --font-family-open_sans: "Open Sans";
  --font-family-open_sans-bold: "Open Sans-Bold";
  --font-family-open_sans-regular: "Open Sans-Regular";
  --font-family-open_sans-semibold: "Open Sans-SemiBold";
  --font-family-poppins: "Poppins";
  --font-family-poppins-medium: "Poppins-Medium";
  --font-family-poppins-semibold: "Poppins-SemiBold";
  --font-family-times_new_roman-bold: "Times New Roman-Bold";
}



.edit-profile-opensans-semi-bold-eerie-black-14px {
  color: var(--eerie-black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-tuna-14px {
  color: var(--tuna);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-normal-regent-gray-12px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-regent-gray-14px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-bold-ocean-blue-pearl-14px {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-16px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-gray-14px {
  color: var(--gray);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-regent-gray-16px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-chateau-green-12px {
  color: var(--chateau-green);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-bold-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-normal-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-normal-gray-14px {
  color: var(--gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-pale-sky-12px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-white-10px {
  color: var(--white);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-electric-violet-16px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-normal-pale-sky-12px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-poppins-medium-amber-18px {
  color: var(--amber);
  font-family: var(--font-family-poppins);
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
}
.edit-profile-opensans-normal-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-normal-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-normal-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-chateau-green-12px {
  color: var(--chateau-green);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-blue-16px {
  color: var(--blue);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-eerie-black-14px {
  color: var(--eerie-black);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-electric-violet-16px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-electric-violet-14px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-eucalyptus-10px {
  color: var(--eucalyptus);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-electric-violet-18px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans);
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-electric-violet-20px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-heather-14px {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-regent-gray-10px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-heather-14px {
  color: var(--heather);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-electric-violet-14px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-eerie-black-13px {
  color: var(--eerie-black-2);
  font-family: var(--font-family-open_sans-regular);
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-timesnewroman-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-times_new_roman-bold);
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-normal-purple-heart-16px {
  color: var(--purple-heart);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-montserrat-black-orient-12px {
  color: var(--orient);
  font-family: var(--font-family-montserrat-black);
  font-size: var(--font-size-s);
  font-weight: 900;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-scarpa-flow-10px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-tahiti-gold-12px {
  color: var(--tahiti-gold);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-normal-regent-gray-16px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-normal-solitude-14px {
  color: var(--solitude);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-electric-violet-18px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans-semibold);
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-ocean-blue-pearl-14px {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-normal-tuna-14px {
  color: var(--tuna);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-circularstd-book-normal-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-circular_std-book);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-bold-scarpa-flow-16px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-22px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl22);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-eucalyptus-12px {
  color: var(--eucalyptus);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-eucalyptus-10px {
  color: var(--eucalyptus);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-solitude-14px {
  color: var(--solitude);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-bold-scarpa-flow-16px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-20px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-20px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-bold-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-bold-ocean-blue-pearl-14px {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-white-10px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-heather-14px {
  color: var(--heather);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-electric-violet-20px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-circularstd-medium-endeavour-10px {
  color: var(--endeavour);
  font-family: var(--font-family-circular_std-medium);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
}
.edit-profile-montserrat-black-orient-12px {
  color: var(--orient);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-weight: 900;
  font-style: normal;
}
.edit-profile-poppins-medium-amber-18px {
  color: var(--amber);
  font-family: var(--font-family-poppins-medium);
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-regent-gray-12px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-black-squeeze-14px {
  color: var(--black-squeeze);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-normal-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-eucalyptus-12px {
  color: var(--eucalyptus);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-normal-heather-14px {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-purple-heart-16px {
  color: var(--purple-heart);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-normal-cornflower-14px {
  color: var(--cornflower);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-tahiti-gold-12px {
  color: var(--tahiti-gold);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-regent-gray-14px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-cornflower-14px {
  color: var(--cornflower);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-bold-scarpa-flow-24px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-heronew-semi-bold-pale-sky-24px {
  color: var(--pale-sky);
  font-family: var(--font-family-hero_new-semibold);
  font-size: var(--font-size-xxl2);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-22px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xxl22);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-circularstd-book-normal-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-circular_std-book);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-regular-normal-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-bold-scarpa-flow-24px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-normal-eerie-black-13px {
  color: var(--eerie-black-2);
  font-family: var(--font-family-open_sans);
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-blue-16px {
  color: var(--blue);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-16px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-regent-gray-10px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-black-squeeze-14px {
  color: var(--black-squeeze);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-bold-white-16px {
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-bold-white-16px {
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-normal-scarpa-flow-10px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.edit-profile-opensans-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.edit-profile-heronew-bold-orient-64px {
  color: var(--orient);
  font-family: var(--font-family-hero_new-bold);
  font-size: 64px;
  font-weight: 700;
  font-style: normal;
}
.edit-profile-opensans-semi-bold-ocean-blue-pearl-14px {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}