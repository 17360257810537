@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,300;1,400&display=swap');

:root {
  --main-blue: #082777;
  --light-grey: #efefef;
  --lightest-blue: #dbe5ff;
  --darkest-blue: #393b3f;
  --light-blue: #2653c9;
  --max-width: 1180px;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;

}

/*BUTTONS*/
/*Pririmary-w-icon styling*/
.Primary-w-icon {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: white;
  width: 148px;
  height: 52px;
  background: #1f3e8b;
  box-shadow: 0px 4px 15px rgba(251, 63, 92, 0.2);
  border-radius: 10px;
  border: 1px solid #1f3e8b;
  cursor: pointer;
}
.Primary-w-icon:hover {
  font-weight: normal;
  text-align: right;
  color: #1f3e8b;
  background: #ffffff;
  border: 2px solid #1f3e8b;
  box-sizing: border-box;
  border-radius: 10px;
}
.Primary-w-icon:disabled {
  font-weight: normal;
  background: rgba(4, 23, 42, 0.2);
  border-radius: 10px;
}
.Primary-w-icon:focus {
  font-weight: normal;
  text-align: right;
  color: #ffffff;
  background: #1f3e8b;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(251, 63, 92, 0.2);
  border-radius: 5px;
}
/*End of pririmary-w-icon styling*/

/*Primary-w-icon styling*/
.Primary-wo-icon {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: white;
  width: 80%;
  height: 52px;
  background: #1f3e8b;
  box-shadow: 0px 4px 15px rgba(251, 63, 92, 0.2);
  border-radius: 10px;
  border: 1px solid #1f3e8b;
  cursor: pointer;
}
.Primary-wo-icon:hover {
  font-weight: normal;
  color: #1f3e8b;
  background: #ffffff;
  border: 2px solid #1f3e8b;
  box-sizing: border-box;
  border-radius: 10px;
}
.Primary-wo-icon:disabled {
  font-weight: normal;
  background: rgba(4, 23, 42, 0.2);
  border-radius: 10px;
}
.Primary-wo-icon:focus {
  font-weight: normal;

  color: #ffffff;
  background: #1f3e8b;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(251, 63, 92, 0.2);
  border-radius: 5px;
}
/*End of primary-wo-icon styling*/

/*Secondary -w-icon*/
.Secondary-w-icon {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: #1f3e8b;
  border: 2px solid #1f3e8b;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #1f3e8b;
  width: 130px;
  height: 52px;
}

.Secondary-w-icon a {
  text-decoration: none;
  color: white;
}

.Secondary-w-icon:hover {
  font-weight: 500;
  color: #ffffff;
  background: #1f3e8b;
  box-shadow: 0px 4px 15px rgba(251, 63, 92, 0.2);
  border-radius: 10px;
}
.Secondary-w-icon:disabled {
  background: rgba(4, 23, 42, 0.2);
  border-radius: 10px;
}
.Secondary-w-icon:focus {
  color: #1f3e8b;
  background: #ffffff;
  border: 2px solid #2653c9;
  box-sizing: border-box;
  border-radius: 5px;
}
/*End of Secondary-w-icon*/

/*Secondary-wo-icons*/
.Secondary-wo-icon:hover {
  font-weight: 500;
  color: #ffffff;
  background: #1f3e8b;
  box-shadow: 0px 4px 15px rgba(251, 63, 92, 0.2);
  border-radius: 10px;
}
.Secondary-wo-icon:disabled {
  background: rgba(4, 23, 42, 0.2);
  border-radius: 10px;
}
.Secondary-wo-icon:focus {
  color: #1f3e8b;
  background: #ffffff;
  border: 2px solid #2653c9;
  box-sizing: border-box;
  border-radius: 5px;
}
/*End of scondary-wo-icons*/
/*BUTTONS*/

/*TYPOGRAPHY*/
.H1-l {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 42px;
  color: #2653c9;
}
.H1-s {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #2653c9;
}
.H2-l {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 30px;
  color: #2653c9;
}
.H2-s {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #2653c9;
}
.H3-l {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #2653c9;
}
.H3-s {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2653c9;
}
.P1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #2653c9;
}
.link-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #1c1c1c;
}
.link-footer {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #1c1c1c;
}
/*TYPOGRAPHY*/

/*TYPOGRAPHY FOR MOBILE*/
.H1-m-l {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #2653c9;
}
.H1-m-s {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 42px;
  color: #2653c9;
}
.H2-m-l {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2653c9;
}
.H2-m-s {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2653c9;
}
.H3-m-l {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2653c9;
}
.H3-m-s {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2653c9;
}
/*TYPOGRAPHY FOR MOBILE*/

/*DROPDOWN*/
.dropdown {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.dropdown-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(4, 23, 42, 0.3);
}
.dropdown-bar {
  width: 311px;
  height: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(4, 23, 42, 0.7);
  box-sizing: border-box;

  border-radius: 5px;
}
.dropdown-bar:disabled {
  color: rgba(4, 23, 42, 0.3);
}
.dropdown-bar:focus {
  border: 2px solid #1c7ed6;
  box-sizing: border-box;
  border-radius: 5px;
  color: #1c7ed6;
}
.dropdown option {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2653c9;
}
/*DROPDOWN*/

/*INPUT FIELD FIELDS*/
.input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.input-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(4, 23, 42, 0.7);
}
.input-field {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(4, 23, 42, 0.7);
  width: 311px;
  height: 48px;
  border: 1px solid rgba(4, 23, 42, 0.7);
  box-sizing: border-box;
  border-radius: 5px;
}
.input-field:disabled {
  background: rgba(4, 23, 42, 0.1);
  border-radius: 5px;
  color: rgba(4, 23, 42, 0.3);
}
.input-field:focus {
  color: #2653c9;
}
.input-label:active {
  color: #2653c9;
}
/*INPUT FIELD FIELDS*/

/*DESCRIPTION FIELDS*/
.description-field {
  font-family: Poppins;
  font-style: normal;
  color: rgba(4, 23, 42, 0.3);
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(4, 23, 42, 0.7);
  box-sizing: border-box;
  border-radius: 5px;
  width: 403px;
  height: 132px;
}
.description-field:focus {
  color: #2653c9;
  background: #ffffff;
  border: 1px solid #1c7ed6;
  box-sizing: border-box;
  border-radius: 5px;
}
.description-field:active {
  color: #2653c9;
}
.bold {
  display: block;
  margin: 10px 0;
}
/*DESCRIPTION FIELDS*/
