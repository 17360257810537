@import url("https://fonts.googleapis.com/css?family=Open+Sans:400|Poppins:500");
@font-face {
  font-family: "Times New Roman-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/TimesNewRoman-Bold");
}
.no-outline {
    outline-style: none;
}
.screen a {
  display: contents;
  text-decoration: none;
  color: white;
}


.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: left;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.frame-8149 {
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: fixed;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.overlap-group1 {
  height: 781px;
  margin-top: -13px;
  position: relative;
  width: 256px;
}

.rectangle-1 {
  background-color: var(--ultramarine);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 13px;
  width: 256px;
}

.version-100 {
  height: 24px;
  left: 35px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 97vh;
  white-space: nowrap;
}

.overview {
  left: 33px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 140px;
}

.tools {
  left: 33px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 296px;
}

.frame-2 {
  background-color: var(--ocean-blue-pearl);
  height: 58px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 256px;
}

.frame-8133 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  left: 0;
  padding: 12px 32px;
  position: absolute;
  top: 90vh;
  transition: all 0.2s ease;
  width: 256px;
}

.frame-8133:hover {
  background-color: 
#4323ff;
}

.icon {
  height: 24px;
  min-width: 24px;
}

.logout {
  letter-spacing: 0;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
}

.frame-8133.frame-8134 {
  top: 376px;
}

.frame-1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  left: 0;
  padding: 12px 32px;
  position: absolute;
  top: 169px;
  transition: all 0.2s ease;
  width: 256px;
}

.frame-1:hover {
  background-color: 
#4323ff;
}
.frame-8131:hover {
  background-color: 
  #4323ff;
}

.icoutline-space-dashboard {
  height: 24px;
  min-width: 24px;
}

.dash-board {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.5px;
  width: -moz-fit-content;
  width: fit-content;
}

.frame-1.frame-8136 {
  top: 328px;
}

.frame-8131 {
  align-items: center;
  border-color: var(--portage);
  display: flex;
  gap: 12px;
  left: 0;
  padding: 12px 32px;
  position: absolute;
  top: 217px;
  width: 256px;
}
.active-link {
  background-color: var(--canary);
  border-left-style: solid;
  border-left-width: 4px;
}

.iconoutlinesound {
  height: 24px;
  min-width: 24px;
}

.my-podcasts {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.5px;
  width: -moz-fit-content;
  width: fit-content;
}

.frame-8146 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 70px;
  left: 25px;
  min-width: 158px;
  position: absolute;
  top: 0;
}

.overlap-group {
  height: 70px;
  position: relative;
  width: 76px;
}

.celecast {
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 49px;
}

.rectangle-99 {
  height: 66px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 76px;
}

.creator {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 14px;
  min-width: 70px;
  white-space: nowrap;
}
:root {
  --black: 
rgba(0, 0, 0, 1);
  --endeavour: 
rgba(0, 89, 172, 1);
  --orient: 
rgba(8, 94, 122, 1);
  --azure-radiance: 
rgba(12, 127, 233, 1);
  --ultramarine: 
rgba(19, 0, 134, 1);
  --eucalyptus: 
rgba(21, 137, 87, 1);
  --eerie-black-2: 
rgba(27, 28, 29, 1);
  --eerie-black: 
rgba(27, 30, 33, 1);
  --dark-blue: 
rgba(33, 0, 227, 0.22);
  --charade: 
rgba(33, 41, 52, 0.071);
  --blue: 
rgba(37, 1, 249, 1);
  --tuna: 
rgba(54, 60, 67, 1);
  --chateau-green: 
rgba(54, 179, 104, 1);
  --canary: 
rgba(67, 35, 255, 1);
  --picton-blue: 
rgba(67, 210, 249, 1);
  --ocean-blue-pearl: 
rgba(81, 60, 204, 1);
  --scarpa-flow: 
rgba(82, 89, 100, 1);
  --electric-violet: 
rgba(98, 0, 238, 1);
  --purple-heart: 
rgba(101, 75, 255, 1);
  --pale-sky: 
rgba(109, 119, 134, 1);
  --blue-marguerite: 
rgba(121, 98, 255, 1);
  --regent-gray: 
rgba(136, 149, 167, 1);
  --gray: 
rgba(145, 145, 145, 1);
  --portage-2: 
rgba(151, 133, 255, 1);
  --cornflower: 
rgba(151, 209, 242, 1);
  --portage: 
rgba(152, 134, 255, 1);
  --delta: 
rgba(162, 162, 162, 1);
  --heather: 
rgba(184, 196, 206, 1);
  --french-pass: 
rgba(189, 243, 252, 1);
  --jagged-ice: 
rgba(196, 239, 223, 1);
  --mischka: 
rgba(206, 214, 222, 1);
  --alto: 
rgba(217, 217, 217, 1);
  --pattens-blue: 
rgba(221, 238, 255, 1);
  --tahiti-gold: 
rgba(224, 131, 4, 1);
  --blue-chalk: 
rgba(233, 228, 255, 1);
  --solitude: 
rgba(234, 246, 252, 1);
  --gallery: 
rgba(237, 237, 237, 1);
  --concrete: 
rgba(242, 243, 245, 1);
  --cultured-pearl: 
rgba(245, 245, 245, 1);
  --black-squeeze: 
rgba(245, 248, 250, 1);
  --white-lilac: 
rgba(249, 247, 255, 1);
  --zircon: 
rgba(249, 248, 255, 1);
  --alabaster: 
rgba(250, 250, 250, 1);
  --amber: 
rgba(255, 196, 0, 1);
  --peach-cream: 
rgba(255, 239, 219, 1);
  --white: 
rgba(255, 255, 255, 1);

  --font-size-xs: 10px;
  --font-size-s: 12px;
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 18px;
  --font-size-xl2: 20px;
  --font-size-xxl2: 24px;
  --font-size-xxl22: 22px;

  --font-family-circular_std-book: "Circular Std-Book";
  --font-family-circular_std-medium: "Circular Std-Medium";
  --font-family-hero_new-bold: "Hero New-Bold";
  --font-family-hero_new-medium: "Hero New-Medium";
  --font-family-hero_new-semibold: "Hero New-SemiBold";
  --font-family-montserrat: "Montserrat";
  --font-family-montserrat-black: "Montserrat-Black";
  --font-family-open_sans: "Open Sans";
  --font-family-open_sans-bold: "Open Sans-Bold";
  --font-family-open_sans-regular: "Open Sans-Regular";
  --font-family-open_sans-semibold: "Open Sans-SemiBold";
  --font-family-poppins: "Poppins";
  --font-family-poppins-medium: "Poppins-Medium";
  --font-family-poppins-semibold: "Poppins-SemiBold";
  --font-family-times_new_roman-bold: "Times New Roman-Bold";
}



.opensans-semi-bold-eerie-black-14px {
  color: var(--eerie-black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.opensans-regular-normal-tuna-14px {
  color: var(--tuna);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-normal-regent-gray-12px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-regent-gray-14px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-bold-ocean-blue-pearl-14px {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.opensans-regular-normal-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.opensans-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-16px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-regular-normal-gray-14px {
  color: var(--gray);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-regular-normal-regent-gray-16px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.opensans-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.opensans-semi-bold-chateau-green-12px {
  color: var(--chateau-green);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.opensans-bold-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.opensans-normal-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.opensans-normal-gray-14px {
  color: var(--gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.opensans-regular-normal-pale-sky-12px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-white-10px {
  color: var(--white);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-electric-violet-16px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-normal-pale-sky-12px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.poppins-medium-amber-18px {
  color: var(--amber);
  font-family: var(--font-family-poppins);
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
}
.opensans-normal-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-normal-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
}
.opensans-regular-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-normal-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-chateau-green-12px {
  color: var(--chateau-green);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-blue-16px {
  color: var(--blue);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-eerie-black-14px {
  color: var(--eerie-black);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-electric-violet-16px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-regular-normal-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-electric-violet-14px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-eucalyptus-10px {
  color: var(--eucalyptus);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-electric-violet-18px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans);
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-electric-violet-20px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-heather-14px {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-regent-gray-10px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.opensans-regular-normal-heather-14px {
  color: var(--heather);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-electric-violet-14px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-regular-normal-eerie-black-13px {
  color: var(--eerie-black-2);
  font-family: var(--font-family-open_sans-regular);
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
}
.opensans-regular-normal-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.timesnewroman-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-times_new_roman-bold);
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
}
.opensans-normal-purple-heart-16px {
  color: var(--purple-heart);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.montserrat-black-orient-12px {
  color: var(--orient);
  font-family: var(--font-family-montserrat-black);
  font-size: var(--font-size-s);
  font-weight: 900;
  font-style: normal;
}
.opensans-regular-normal-scarpa-flow-10px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-tahiti-gold-12px {
  color: var(--tahiti-gold);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.opensans-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
}
.opensans-normal-regent-gray-16px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.opensans-normal-solitude-14px {
  color: var(--solitude);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-electric-violet-18px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans-semibold);
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-ocean-blue-pearl-14px {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-normal-tuna-14px {
  color: var(--tuna);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.circularstd-book-normal-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-circular_std-book);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.opensans-bold-scarpa-flow-16px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-22px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl22);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-eucalyptus-12px {
  color: var(--eucalyptus);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-eucalyptus-10px {
  color: var(--eucalyptus);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.opensans-regular-normal-solitude-14px {
  color: var(--solitude);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-bold-scarpa-flow-16px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-20px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-20px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.opensans-bold-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.opensans-regular-normal-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.opensans-bold-ocean-blue-pearl-14px {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
}
.opensans-semi-bold-white-10px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-heather-16px {
  color: var(--heather);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-heather-14px {
  color: var(--heather);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-electric-violet-20px {
  color: var(--electric-violet);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.circularstd-medium-endeavour-10px {
  color: var(--endeavour);
  font-family: var(--font-family-circular_std-medium);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
}
.montserrat-black-orient-12px {
  color: var(--orient);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-weight: 900;
  font-style: normal;
}
.poppins-medium-amber-18px {
  color: var(--amber);
  font-family: var(--font-family-poppins-medium);
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
}
.opensans-regular-normal-regent-gray-12px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-black-squeeze-14px {
  color: var(--black-squeeze);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-normal-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-eucalyptus-12px {
  color: var(--eucalyptus);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.opensans-normal-heather-14px {
  color: var(--heather);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-regular-normal-purple-heart-16px {
  color: var(--purple-heart);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.opensans-normal-cornflower-14px {
  color: var(--cornflower);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-tahiti-gold-12px {
  color: var(--tahiti-gold);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-regent-gray-14px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-regular-normal-cornflower-14px {
  color: var(--cornflower);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-bold-scarpa-flow-24px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
}
.heronew-semi-bold-pale-sky-24px {
  color: var(--pale-sky);
  font-family: var(--font-family-hero_new-semibold);
  font-size: var(--font-size-xxl2);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-22px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-xxl22);
  font-weight: 600;
  font-style: normal;
}
.circularstd-book-normal-scarpa-flow-12px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-circular_std-book);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.opensans-regular-normal-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.opensans-bold-scarpa-flow-24px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl2);
  font-weight: 700;
  font-style: normal;
}
.opensans-normal-eerie-black-13px {
  color: var(--eerie-black-2);
  font-family: var(--font-family-open_sans);
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-blue-16px {
  color: var(--blue);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-16px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-l);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-regent-gray-10px {
  color: var(--regent-gray);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-black-squeeze-14px {
  color: var(--black-squeeze);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-bold-white-16px {
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.opensans-bold-white-16px {
  font-family: var(--font-family-open_sans-bold);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
}
.opensans-normal-scarpa-flow-10px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.opensans-semi-bold-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.opensans-semi-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl2);
  font-weight: 600;
  font-style: normal;
}
.opensans-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.heronew-bold-orient-64px {
  color: var(--orient);
  font-family: var(--font-family-hero_new-bold);
  font-size: 64px;
  font-weight: 700;
  font-style: normal;
}
.opensans-semi-bold-ocean-blue-pearl-14px {
  color: var(--ocean-blue-pearl);
  font-family: var(--font-family-open_sans-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}