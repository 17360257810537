@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,600|Poppins:500|Roboto:400,500");
/* @import "~antd/dist/antd.css";
@import "~antd/dist/antd.variable.css"; */
@font-face {
  font-family: "Times New Roman-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/TimesNewRoman-Bold");
}
@font-face {
  font-family: "Hero New-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("https://fonts.animaapp.com/HeroNew-SemiBold") format("opentype");
}

.profile-filled-screen a {
  display: contents;
  text-decoration: none;
}

.profile-filled-container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.profile-filled-container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.profile-filled-valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-filled-profile-filled {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  height: 899px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.profile-filled-flex-col {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 3px;
  min-height: 824px;
  position: relative;
  width: 1005px;
  margin-left: 275px;
}

.profile-filled-profile-content-internal-container {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--mischka);
  border-radius: 8px;
  display: flex;
  gap: 73px;
  height: 779px;
  margin-right: 45px;
  min-width: 890px;
  padding: 40px 32px;
}

.profile-filled-flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  min-height: 622px;
  position: relative;
  width: 244px;
}

.profile-filled-profile-img {
  height: 220px;
  width: 231px;
}

.profile-filled-top-2-section-header {
  align-items: flex-end;
  display: flex;
  gap: 8px;
  height: 12px;
  margin-top: 40px;
  min-width: 205px;
}

.profile-filled-section-title-1 {
  letter-spacing: 0;
  line-height: 12px;
  min-width: 27px;
  white-space: nowrap;
}

.profile-filled-section-bleed-1 {
  height: 1px;
  margin-bottom: 2px;
  object-fit: cover;
  width: 168px;
}

.profile-filled-genre-section-heading {
  align-items: flex-end;
  display: flex;
  gap: 8px;
  height: 12px;
  margin-top: 32px;
  min-width: 244px;
}

.profile-filled-section-title-2 {
  letter-spacing: 0;
  line-height: 12px;
  min-width: 36px;
  white-space: nowrap;
}

.profile-filled-section-bleed-2 {
  height: 1px;
  margin-bottom: 2px;
  object-fit: cover;
  width: 198px;
}

.profile-filled-name {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 21px;
  white-space: nowrap;
}

.profile-filled-flex-col-item {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 4px;
  white-space: nowrap;
}

.profile-filled-flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 613px;
  width: 482px;
}

.profile-filled-flex-row {
  align-items: flex-start;
  display: flex;
  height: 213px;
  margin-left: 0.01px;
  min-width: 482px;
  position: relative;
}

.profile-filled-flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 213px;
  width: 180px;
}

.profile-filled-account-name,
.profile-filled-rating-score {
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.profile-filled-profession {
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 18px;
  white-space: nowrap;
}

.profile-filled-flex-row-1 {
  align-items: flex-end;
  display: flex;
  height: 56px;
  margin-top: 38px;
  min-width: 160px;
  position: relative;
}

.profile-filled-ranking {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 56px;
  width: 64px;
}

.profile-filled-rankings,
.profile-filled-location-1,
.profile-filled-date,
.profile-filled-female {
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.profile-filled-about {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  margin-top: 3px;
  width: -moz-fit-content;
  width: fit-content;
}

.profile-filled-iconboldprofile-circle {
  height: 20px;
  min-width: 20px;
}

.profile-filled-about-1 {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.profile-filled-location {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  margin-left: 33px;
  margin-top: 5px;
  min-width: 98px;
}

.profile-filled-iconboldlocation {
  height: 20px;
  width: 20px;
}
.top-2-title-div-1 {
  margin-top: 41px;
  margin-bottom: 48px;
}

.profile-filled-overlap-group2 {
  height: 3px;
  margin-top: 4px;
  position: relative;
  width: 482px;
}

.profile-filled-line-1 {
  height: 2px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 482px;
}

.profile-filled-rectangle-3135 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 64px;
}

.profile-filled-contact-information {
  letter-spacing: 0;
  line-height: 12px;
  margin-left: 0.01px;
  margin-top: 40px;
  white-space: nowrap;
}

.profile-filled-flex-row-2 {
  align-items: center;
  display: flex;
  gap: 65px;
  height: 20px;
  margin-left: 0.01px;
  margin-top: 36px;
  min-width: 236px;
}

.profile-filled-phone {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 47px;
  white-space: nowrap;
}

.profile-filled-text-1 {
  letter-spacing: 0;
  line-height: 16px;
  min-width: 124px;
  white-space: nowrap;
}

.profile-filled-address-container {
  align-items: center;
  display: flex;
  gap: 53px;
  height: 20px;
  margin-left: 0.01px;
  margin-top: 16px;
  min-width: 299px;
}

.profile-filled-address {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 59px;
  white-space: nowrap;
}

.profile-filled-address-1 {
  letter-spacing: 0;
  line-height: 16px;
  min-width: 187px;
  white-space: nowrap;
}

.profile-filled-flex-row-3 {
  align-items: center;
  display: flex;
  gap: 66px;
  height: 20px;
  margin-left: 0.01px;
  margin-top: 16px;
  min-width: 278px;
}

.profile-filled-e-mail {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 46px;
  white-space: nowrap;
}

.profile-filled-hellolemonfalanacom {
  letter-spacing: 0;
  line-height: 16px;
  min-width: 166px;
  white-space: nowrap;
}

.profile-filled-flex-row-4 {
  align-items: center;
  display: flex;
  gap: 82px;
  height: 20px;
  margin-left: 0.01px;
  margin-top: 16px;
  min-width: 267px;
}

.profile-filled-site {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 30px;
  white-space: nowrap;
}

.profile-filled-wwwnseabasi001com {
  letter-spacing: 0;
  line-height: 16px;
  min-width: 155px;
  white-space: nowrap;
}

.profile-filled-basic-information {
  letter-spacing: 0;
  line-height: 12px;
  margin-left: 0.01px;
  margin-top: 143px;
  white-space: nowrap;
}

.profile-filled-flex-row-5 {
  align-items: flex-start;
  display: flex;
  gap: 50px;
  height: 20px;
  margin-left: 0.01px;
  margin-top: 24px;
  min-width: 194px;
}

.profile-filled-birthday {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 62px;
  white-space: nowrap;
}

.profile-filled-flex-row-6 {
  align-items: flex-start;
  display: flex;
  gap: 58px;
  height: 20px;
  margin-left: 0.01px;
  margin-top: 16px;
  min-width: 161px;
}

.profile-filled-gender {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 54px;
  white-space: nowrap;
}

.profile-filled-sidebar {
  align-items: flex-start;
  background-color: var(--ultramarine);
  display: flex;
  flex-direction: column;
  min-height: 899px;
  position: relative;
  width: 256px;
  left: 20px;
}

.profile-filled-logo-container {
  height: 70px;
  position: relative;
  width: 256px;
}

.profile-filled-logo-frame {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 70px;
  left: 32px;
  min-width: 158px;
  position: absolute;
  top: 0;
}

.profile-filled-overlap-group {
  height: 70px;
  position: relative;
  width: 76px;
}

.profile-filled-celecast {
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 49px;
}

.profile-filled-rectangle-99 {
  height: 66px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 76px;
}

.profile-filled-creator {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 14px;
  min-width: 70px;
  white-space: nowrap;
}

.profile-filled-overview {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 33px;
  margin-top: 81px;
  min-height: 23px;
  width: 69px;
}

.profile-filled-tools {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 33px;
  margin-top: 42px;
  min-height: 23px;
  width: 45px;
}

.profile-filled-version-100 {
  height: 29px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 35px;
  margin-top: 23px;
  text-align: center;
  white-space: nowrap;
  width: 97px;
}

.profile-filled-logo-background {
  background-color: var(--ocean-blue-pearl);
  height: 69px;
  left: 0;
  position: absolute;
  top: 0;
  width: 256px;
}

.profile-filled-dashboard-frame {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  margin-top: 16px;
  padding: 12px 32px;
  transition: all 0.2s ease;
  width: 189px;
}

.profile-filled-dashboard-frame:hover,
.profile-filled-dashboard-frame.s-frame:hover {
  background-color: #4323ff;
}

.profile-filled-icoutline-space-dashboard {
  height: 24px;
  min-width: 24px;
}

.profile-filled-dash-board {
  letter-spacing: 0;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
}

.profile-filled-dashboard-frame.s-frame,
.profile-filled-dashboard-frame.s-frame-1 {
  margin-top: 9px;
}

.profile-filled-dashboard-frame.s-frame-1.profile-filled-icoutline-space-dashboard,
.profile-filled-dashboard-frame.profile-frame.profile-filled-icoutline-space-dashboard,
.profile-filled-dashboard-frame.s-frame.profile-filled-icoutline-space-dashboard,
.profile-filled-dashboard-frame.logout-frame.profile-filled-icoutline-space-dashboard {
  height: 24px;
}

.profile-filled-dashboard-frame.profile-frame {
  background-color: var(--canary);
  border-color: var(--portage-2);
  border-left-style: solid;
  border-left-width: 4px;
  cursor: unset;
  margin-top: 20px;
  transition: unset;
}

.profile-filled-dashboard-frame.profile-frame.profile-filled-dash-board {
  margin-top: -1.5px;
}

.profile-filled-dashboard-frame.logout-frame {
  margin-top: 287px;
}

.profile-filled-header-tab {
  height: 81px;
  position: relative;
  width: 1005px;
}

.profile-filled-top-nav-bar-content {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    gap: 127px;
    height: 1px;
    left: 20px;
    padding: 16px 0px 16px 22px;
    position: absolute;
    top: 0;
    width: 1005px;
  
}

.profile-filled-frame-8106 {
  align-items: center;
  display: flex;
  gap: 128px;
  height: 45px;
  position: relative;
  width: 120px;
}

.profile-filled-frame-8141 {
  align-items: flex-start;
  display: flex;
  gap: 50px;
  position: relative;
  width: 430px;
}

.profile-filled-section-label {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  margin-right: -98px;
  padding: 0px 8px;
  width: -moz-fit-content;
  width: fit-content;
}

.profile-filled-label {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  width: 202px;
}

.profile-filled-text-field {
  height: 45px;
}

.profile-filled-frame-8142 {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  height: 56px;
  left: 828px;
  min-width: 136px;
  position: absolute;
  top: 11px;
}

.profile-filled-fab {
  border-radius: 24px;
  height: 48px;
}

.profile-filled-avatar {
  border-radius: 27.5px;
  height: 55px;
}

.profile-filled-top-2-title-div-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 39px;
  min-height: 65px;
  width: 240px;
}

.profile-filled-company-name {
  letter-spacing: 0;
  line-height: 17px;
  white-space: nowrap;
}

.profile-filled-company-address {
  letter-spacing: 0;
  line-height: 20px;
  min-height: 40px;
}

.profile-filled-top-2-title-div-1.top-2-title-div-2 {
  margin-top: 48px;
}

.profile-filled-rating {
  height: 24px;
  margin-bottom: 4px;
}

.profile-filled-edit-profile-button {
  border-radius: 4px;
  height: 43px;
  margin-left: 78px;
  margin-top: 1px;
}

:root {
    --black
  : rgba(0, 0, 0, 1);
    --endeavour
  : rgba(0, 89, 172, 1);
    --orient
  : rgba(8, 94, 122, 1);
    --azure-radiance
  : rgba(12, 127, 233, 1);
    --ultramarine
  : rgba(19, 0, 134, 1);
    --eucalyptus
  : rgba(21, 137, 87, 1);
    --eerie-black-2
  : rgba(27, 28, 29, 1);
    --eerie-black
  : rgba(27, 30, 33, 1);
    --dark-blue
  : rgba(33, 0, 227, 0.22);
    --charade
  : rgba(33, 41, 52, 0.071);
    --blue
  : rgba(37, 1, 249, 1);
    --tuna
  : rgba(54, 60, 67, 1);
    --chateau-green
  : rgba(54, 179, 104, 1);
    --canary
  : rgba(67, 35, 255, 1);
    --picton-blue
  : rgba(67, 210, 249, 1);
    --ocean-blue-pearl
  : rgba(81, 60, 204, 1);
    --scarpa-flow
  : rgba(82, 89, 100, 1);
    --electric-violet
  : rgba(98, 0, 238, 1);
    --purple-heart
  : rgba(101, 75, 255, 1);
    --pale-sky
  : rgba(109, 119, 134, 1);
    --blue-marguerite
  : rgba(121, 98, 255, 1);
    --regent-gray
  : rgba(136, 149, 167, 1);
    --gray
  : rgba(145, 145, 145, 1);
    --portage-2
  : rgba(151, 133, 255, 1);
    --cornflower
  : rgba(151, 209, 242, 1);
    --portage
  : rgba(152, 134, 255, 1);
    --delta
  : rgba(162, 162, 162, 1);
    --heather
  : rgba(184, 196, 206, 1);
    --french-pass
  : rgba(189, 243, 252, 1);
    --jagged-ice
  : rgba(196, 239, 223, 1);
    --mischka
  : rgba(206, 214, 222, 1);
    --alto
  : rgba(217, 217, 217, 1);
    --pattens-blue
  : rgba(221, 238, 255, 1);
    --tahiti-gold
  : rgba(224, 131, 4, 1);
    --blue-chalk
  : rgba(233, 228, 255, 1);
    --solitude
  : rgba(234, 246, 252, 1);
    --gallery
  : rgba(237, 237, 237, 1);
    --concrete
  : rgba(242, 243, 245, 1);
    --cultured-pearl
  : rgba(245, 245, 245, 1);
    --black-squeeze
  : rgba(245, 248, 250, 1);
    --white-lilac
  : rgba(249, 247, 255, 1);
    --zircon
  : rgba(249, 248, 255, 1);
    --alabaster
  : rgba(250, 250, 250, 1);
    --amber
  : rgba(255, 196, 0, 1);
    --peach-cream
  : rgba(255, 239, 219, 1);
    --white
  : rgba(255, 255, 255, 1);
  
    --font-size-xs
  : 10px;
    --font-size-s
  : 12px;
    --font-size-m
  : 14px;
    --font-size-l
  : 16px;
    --font-size-xl
  : 18px;
    --font-size-xl2
  : 20px;
    --font-size-xxl2
  : 24px;
    --font-size-xxl22
  : 22px;
  
    --font-family-circular_std-book
  : "Circular Std-Book";
    --font-family-circular_std-medium
  : "Circular Std-Medium";
    --font-family-hero_new-bold
  : "Hero New-Bold";
    --font-family-hero_new-medium
  : "Hero New-Medium";
    --font-family-hero_new-semibold
  : "Hero New-SemiBold";
    --font-family-montserrat
  : "Montserrat";
    --font-family-montserrat-black
  : "Montserrat-Black";
    --font-family-open_sans
  : "Open Sans";
    --font-family-open_sans-bold
  : "Open Sans-Bold";
    --font-family-open_sans-semibold
  : "Open Sans-SemiBold";
    --font-family-poppins
  : "Poppins";
    --font-family-poppins-semibold
  : "Poppins-SemiBold";
    --font-family-times_new_roman-bold
  : "Times New Roman-Bold";
  }
  
  
  
  
      
  
 .profile-filled-opensans-semi-bold-eerie-black-14px
   {
    color: var(--eerie-black
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-white-20px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-xl2
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-regent-gray-12px
   {
    color: var(--regent-gray
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-s
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-regent-gray-14px
   {
    color: var(--regent-gray
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-ocean-blue-pearl-14px
   {
    color: var(--ocean-blue-pearl
  );
    font-family: var(--font-family-open_sans-bold
  );
    font-size: var(--font-size-m
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-white-14px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans-bold
  );
    font-size: var(--font-size-m
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-16px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-pale-sky-14px
   {
    color: var(--pale-sky
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-white-14px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-chateau-green-12px
   {
    color: var(--chateau-green
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-s
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-pale-sky-14px
   {
    color: var(--pale-sky
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-scarpa-flow-12px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-s
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-gray-14px
   {
    color: var(--gray
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-12px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-s
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-14px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-white-10px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-xs
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-electric-violet-16px
   {
    color: var(--electric-violet
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-pale-sky-12px
   {
    color: var(--pale-sky
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-s
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-poppins-medium-amber-18px
   {
    color: var(--amber
  );
    font-family: var(--font-family-poppins
  );
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-heather-16px
   {
    color: var(--heather
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-pale-sky-14px
   {
    color: var(--pale-sky
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-pale-sky-14px
   {
    color: var(--pale-sky
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-black-24px
   {
    color: var(--black
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xxl2
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-heather-16px
   {
    color: var(--heather
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-eerie-black-12px
   {
    color: var(--eerie-black
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-s
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-chateau-green-12px
   {
    color: var(--chateau-green
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-s
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-blue-16px
   {
    color: var(--blue
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-eerie-black-14px
   {
    color: var(--eerie-black
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-electric-violet-16px
   {
    color: var(--electric-violet
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-electric-violet-14px
   {
    color: var(--electric-violet
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-white-16px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-eucalyptus-10px
   {
    color: var(--eucalyptus
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xs
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-electric-violet-18px
   {
    color: var(--electric-violet
  );
    font-family: var(--font-family-open_sans
  );
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-white-16px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-electric-violet-20px
   {
    color: var(--electric-violet
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xl2
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-heather-14px
   {
    color: var(--heather
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-regent-gray-10px
   {
    color: var(--regent-gray
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-xs
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-electric-violet-14px
   {
    color: var(--electric-violet
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-black-14px
   {
    color: var(--black
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-white-14px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-timesnewroman-bold-white-18px
   {
    color: var(--white
  );
    font-family: var(--font-family-times_new_roman-bold
  );
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-purple-heart-16px
   {
    color: var(--purple-heart
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-montserrat-black-orient-12px
   {
    color: var(--orient
  );
    font-family: var(--font-family-montserrat-black
  );
    font-size: var(--font-size-s
  );
    font-weight: 900;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-tahiti-gold-12px
   {
    color: var(--tahiti-gold
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-s
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-black-24px
   {
    color: var(--black
  );
    font-family: var(--font-family-open_sans-bold
  );
    font-size: var(--font-size-xxl2
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-regent-gray-16px
   {
    color: var(--regent-gray
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-solitude-14px
   {
    color: var(--solitude
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-black-14px
   {
    color: var(--black
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-electric-violet-18px
   {
    color: var(--electric-violet
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-ocean-blue-pearl-14px
   {
    color: var(--ocean-blue-pearl
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-tuna-14px
   {
    color: var(--tuna
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-circularstd-book-normal-heather-16px
   {
    color: var(--heather
  );
    font-family: var(--font-family-circular_std-book
  );
    font-size: var(--font-size-l
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-scarpa-flow-16px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans-bold
  );
    font-size: var(--font-size-l
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-22px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xxl22
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-eucalyptus-12px
   {
    color: var(--eucalyptus
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-s
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-eucalyptus-10px
   {
    color: var(--eucalyptus
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-xs
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-scarpa-flow-16px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-20px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-xl2
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-20px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xl2
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-white-14px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-12px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-s
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-pale-sky-14px
   {
    color: var(--pale-sky
  );
    font-family: var(--font-family-open_sans-bold
  );
    font-size: var(--font-size-m
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-ocean-blue-pearl-14px
   {
    color: var(--ocean-blue-pearl
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-white-10px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xs
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-heather-16px
   {
    color: var(--heather
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-heather-14px
   {
    color: var(--heather
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-electric-violet-20px
   {
    color: var(--electric-violet
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-xl2
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-circularstd-medium-endeavour-10px
   {
    color: var(--endeavour
  );
    font-family: var(--font-family-circular_std-medium
  );
    font-size: var(--font-size-xs
  );
    font-weight: 500;
    font-style: normal;
  }
  
      
  
 .profile-filled-montserrat-black-orient-12px
   {
    color: var(--orient
  );
    font-family: var(--font-family-montserrat
  );
    font-size: var(--font-size-s
  );
    font-weight: 900;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-black-squeeze-14px
   {
    color: var(--black-squeeze
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-scarpa-flow-14px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-eucalyptus-12px
   {
    color: var(--eucalyptus
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-s
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-heather-14px
   {
    color: var(--heather
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-cornflower-14px
   {
    color: var(--cornflower
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-tahiti-gold-12px
   {
    color: var(--tahiti-gold
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-s
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-regent-gray-14px
   {
    color: var(--regent-gray
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-scarpa-flow-24px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans-bold
  );
    font-size: var(--font-size-xxl2
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-heronew-semi-bold-pale-sky-24px
   {
    color: var(--pale-sky
  );
    font-family: var(--font-family-hero_new-semibold
  );
    font-size: var(--font-size-xxl2
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-22px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-xxl22
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-circularstd-book-normal-scarpa-flow-12px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-circular_std-book
  );
    font-size: var(--font-size-s
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-scarpa-flow-24px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xxl2
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-eerie-black-13px
   {
    color: var(--eerie-black-2
  );
    font-family: var(--font-family-open_sans
  );
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-blue-16px
   {
    color: var(--blue
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-16px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-l
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-regent-gray-10px
   {
    color: var(--regent-gray
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xs
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-black-squeeze-14px
   {
    color: var(--black-squeeze
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-white-16px
   {
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-bold-white-16px
   {
    font-family: var(--font-family-open_sans-bold
  );
    font-size: var(--font-size-l
  );
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-scarpa-flow-10px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xs
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-scarpa-flow-14px
   {
    color: var(--scarpa-flow
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-white-20px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-xl2
  );
    font-weight: 600;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-normal-white-16px
   {
    color: var(--white
  );
    font-family: var(--font-family-open_sans
  );
    font-size: var(--font-size-l
  );
    font-weight: 400;
    font-style: normal;
  }
  
      
  
 .profile-filled-heronew-bold-orient-64px
   {
    color: var(--orient
  );
    font-family: var(--font-family-hero_new-bold
  );
    font-size: 64px;
    font-weight: 700;
    font-style: normal;
  }
  
      
  
 .profile-filled-opensans-semi-bold-ocean-blue-pearl-14px
   {
    color: var(--ocean-blue-pearl
  );
    font-family: var(--font-family-open_sans-semibold
  );
    font-size: var(--font-size-m
  );
    font-weight: 600;
    font-style: normal;
  }

  